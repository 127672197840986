@import "/src/@fuse/scss/fuse.scss";
vertical-layout-1 {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;

  #main {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1;
    min-width: 0;

    // Container 1
    >.container-1 {
      position: relative;
      display: flex;
      flex: 1 1 0%;
      width: 100%;
      min-height: 0;
      min-width: 0;

      // Container 2
      >.container-1 {
        position: relative;
        display: flex;
        flex: 1 1 0%;
        flex-direction: column;
        min-width: 0;

        // Container 3 (Scrollable)
        >.container-1 {
          position: relative;
          display: flex;
          flex: 1 1 0%;
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;

          // Content component
          content {

            &.inner-scroll {
              flex: 1 1 0%;
              min-height: 0;

              >*:not(router-outlet) {
                flex: 1 1 0%;
              }
            }
          }
        }
      }
    }
  }
}

fuse-sidebar {
  &.navbar-fuse-sidebar {
    overflow: hidden;

    &.folded:not(.unfolded) {
      navbar {
        navbar-vertical-style-1 {
          .navbar-content {
            .material2 {
              .nav-item {
                .nav-link {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}


.offlineDiv {
  width: 100%;
  height: 25px;
  background-color: rgba(218, 146, 58, 0.904);
  text-align: center;
  color: white;
  font-weight: 900;
  letter-spacing: 1.75px;
  text-transform: uppercase;
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.offline-beta {
  display: flex;
  background-color: white;
  color: rgba(218, 146, 58, 0.904);
  width: 50px;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
}

.warning-icon {
  color: white;
  font-size: 15px;
  font-weight: 600;
  min-width: 12px !important;
  min-height: 15px !important;
  height: 15px !important;
  width: 15px !important;
  cursor: pointer;
}