import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, HostListener } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { StorageUtils } from 'app/shared/storage-utils';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { locale as francais } from './i18n/fr';
import { locale as arabe } from './i18n/dz';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { NotifiersService } from 'app/services/notifiers.service';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ContextOfflineComponent } from './context-offline/context-offline.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NotificationsService } from 'app/services/notifications.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('langAnimation', [
            // ...
            state('open', style({
                height: '110px',
                overflow: 'hidden',
                opacity: 1,
            })),
            state('closed', style({
                height: '0px',
                overflow: 'hidden',
                opacity: 0.5,
            })),
            transition('* => *', [
                animate('0.5s')
            ]),
        ]),
    ],
})

export class ToolbarComponent implements OnInit, OnDestroy {
    minimalWidthDisplay = false;
    options = [];
    displayBC = true;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    userData: any;
    imgSrc = environment.ENDPOINTS.PROFIL_IMG_URL;
    languages: any;
    defaultSrcImg = '../../../../assets/icons/material-icons/new/user.svg';
    inPlugin: boolean;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    // Private
    private _unsubscribeAll: Subject<any>;
    role: any;
    isAdminClient: any;
    isAdminContext: any;
    context: any;
    fuseConfig: any
    subscription: Subscription;
    showLanguages: boolean = false;
    pendingNotifs: boolean = false;
    notifPage: number = 0;
    notifPageCount: number = 10;
    sort = [{ prop: 'id', direction: 'asc' }];
    notifications: any[] = [];
    totalNotifications: number = 0;
    folder = 'folder';
    notViewedNotificationsCount: number = 0;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer,
        private translateService: TranslateService,
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router,
        private authService: AuthService,
        private notifiersService: NotifiersService,
        private commonService: CommonService,
        private dialog: MatDialog,
        private notifier: NotifiersService,
        private notificationsService: NotificationsService,
        private spinnerService: SpinnerVisibilityService,
    ) {
        this.isAdminContext = StorageUtils.getContext() === 'admin';
        fuseTranslationLoaderService.loadTranslations(francais, arabe);
        this.languages = [{ id: 'fr', title: 'Français', flag: 'fr' }, { id: 'dz', title: 'العربية', flag: 'dz-disable' }];
        iconRegistry.addSvgIcon('emptyNotif', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/notificationsnone.svg'));
        iconRegistry.addSvgIcon('blackNotif', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/notificationsblack.svg'));
        // set folder navigation
        this.navigation = navigation;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        if (StorageUtils.getUser()) {
            this.userData = StorageUtils.getUser();
        } else {
            this.userData = 'firstName lastName';
        }
        this.imgSrc = this.userData.attachmentId ? environment.ENDPOINTS.PROFIL_IMG_URL : this.defaultSrcImg;

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

        this.subscription = this.notifiersService.updateProfile$.subscribe(response => {
            this.userData = response;
            StorageUtils.setUser(response);
        });
        this.fetchNotifications();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }



    @HostListener('window:resize', [])
    onResize() {
        if (window.innerWidth > 500) {
            if (this.minimalWidthDisplay !== false) { this.minimalWidthDisplay = false; }
        } else {
            if (this.minimalWidthDisplay !== true) { this.minimalWidthDisplay = true; }
        }
        if (window.innerWidth > 1080) {
            if (this.displayBC !== true) { this.displayBC = true; }
        } else {
            if (this.displayBC !== false) { this.displayBC = false; }
        }
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this.notifiersService.refreshDatatable();
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...

    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        console.log(lang);

        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        this.notifier.updateLang(lang);
    }

    changeRoleEmitter(role) {
        this.role = role;
    }
    changeIsAdminClientEmitter(isAdminClient) {
        this.isAdminClient = isAdminClient;
    }

    changeContext(type) {
        this.isAdminContext = type === 'administration';
        this.context = type;
        this.notifiersService.changeContext(type);
    }

    goTo(route) {
        switch (route) {
            case 'profile':
                this.router.navigate(['profile']);
                break;
            case 'logout':
                this.authService.logout().subscribe(res => {
                    this.commonService.resetLocalStorage();
                    this.authService.setAuthenticated(false);
                    this.router.navigate(['/login']);
                });
                break;
        }
    }

    openPopup() {
        const message = 'admins.deleteAdmin.deleteMessage';
        const options = [
            { text: 'admins.deleteAdmin.deleteOk', value: true },
            { text: 'admins.deleteAdmin.deleteCancel', value: false }
        ];
        const deletePopUp = this.dialog.open(ContextOfflineComponent, { data: { options, message } });
        // deletePopUp.afterClosed().subscribe(res => {

        // });

    }

    showHideLangOpts() {
        this.showLanguages = !this.showLanguages;
    }

    fetchNotifications() {
        this.notifPage = 0;
        this.notifications = [];
        this.getAllNotifications();
    }

    getAllNotifications() {
        this.pendingNotifs = true;
        const selectedFolderId = StorageUtils.getSelectedFolderId();
        if (selectedFolderId !== null) {
            this.notificationsService.getAllNotifications(StorageUtils.getSelectedFolderId(), this.notifPage, this.notifPageCount, this.sort[0].prop, this.sort[0].direction).subscribe(res => {
                if (this.totalNotifications === 0) {
                    this.totalNotifications = res.headers.get('X-Total-Count') ? Number(res.headers.get('X-Total-Count')) : 0;
                }
                this.notViewedNotificationsCount = res.headers.get('x-active-notifications') ? Number(res.headers.get('x-active-notifications')) : 0;
                res.body.forEach(notif => {
                    if (!this.notifications.find(data => data.id === notif.id)) {
                        this.notifications.push(notif);
                    }
                });
                this.notifications = this.notifications.sort((a, b) => {
                    // if (a.viewed !== b.viewed) {
                    //     return !a.viewed ? -1 : 1;
                    // }
                    const dateA = new Date(a.date).getTime();
                    const dateB = new Date(b.date).getTime();
                    return dateA - dateB;
                });

                this.pendingNotifs = false;
            }, error => {
                this.pendingNotifs = false;
            })
        }
    }
    onScroll(event) {
        if (this.notifications?.length < this.totalNotifications) {
            this.notifPage++;
            this.getAllNotifications();
        }
    }

    redirectTo(notif) {
        if (!notif.viewed) {
            this.spinnerService.show();
            this.notificationsService.updateViewedNotification(StorageUtils.getSelectedFolderId(), notif.id).subscribe(res => {
                this.getAllNotifications();
                this.navigateTo(notif);
                this.spinnerService.hide();
            }, error => {
                this.spinnerService.hide();
            })
        } else {
            this.navigateTo(notif);
        }
    }
    navigateTo(notif) {
        switch (notif.documentType) {
            case 'QUOTE':
                this.router.navigate([this.folder, StorageUtils.getSelectedFolderId(), 'all-quotes', notif.documentId]);
                break;
            case 'DELIVERY_NOTE':
                this.router.navigate([this.folder, StorageUtils.getSelectedFolderId(), 'all-delivery-notes', notif.documentId]);
                break;
            case 'PURCHASE_ORDER':
                this.router.navigate([this.folder, StorageUtils.getSelectedFolderId(), 'order-vouchers', notif.documentId]);
                break;
            default:
                break;
        }
    }



}
