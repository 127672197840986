import { PrivilegesService } from 'app/services/privileges.service';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'app/services/account.service';
import { AuthService } from 'app/services/auth.service';
import { CommonService } from 'app/services/common.service';
import { FolderService } from 'app/services/folder.service';
import { NotifiersService } from 'app/services/notifiers.service';
import { StorageUtils } from 'app/shared/storage-utils';
import { environment } from 'environments/environment';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-context-selector',
    templateUrl: './context-selector.component.html',
    styleUrls: ['./context-selector.component.scss']
})
export class ContextSelectorComponent implements OnInit {
    @Input()
    minimalWidthDisplay: any;
    @Input()
    context: any;
    selectorForm: UntypedFormGroup;
    selecorControl: UntypedFormControl = new UntypedFormControl(Validators.compose([]));
    options = [];
    adminOptions = [];
    filteredOptions: Observable<any[]>;
    defaultSrcImg = '../../../../assets/images/avatars/profile.jpg';
    folderLogo = environment.ENDPOINTS.ATTECHMENT_URL;
    @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;
    selectedFolder: any;
    folders: any[];
    isAdminContext = StorageUtils.getContext() === 'admin';
    clientAccounts: any;
    isAdminClient: boolean;
    selectedAccountId: any;
    selectedAccount: any;
    isAccountClientExpired = false;
    accounts = [];
    emptyFolder: boolean;
    nrNotifications = [];
    updateFolderListNotSub: Subscription;
    updateLogoImgNotifSubs: Subscription;
    deleteFolderNotiSub: Subscription;
    updateFolderSelectedNotifSub: Subscription;
    loading: boolean;
    accountsClients = [];
    page = 0;
    size = 10;
    totalCount = 0;
    role: string;
    errorSnackbar = 'error-snackbar';
    fuseConfig: any;
    @Output() changeRoleEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeIsAdminClientEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeIsAdminContextEmitter: EventEmitter<any> = new EventEmitter<any>();
    private _unsubscribeAll: Subject<any>;
    constructor(
        private spinner: SpinnerVisibilityService,
        private fuseConfigService: FuseConfigService, private fuseSidebarService: FuseSidebarService, private router: Router,
        private translateService: TranslateService, private authService: AuthService, private formBuilder: UntypedFormBuilder,
        private notificationService: NotifiersService, private folderService: FolderService,
        private fuseNavigationService: FuseNavigationService, private accountService: AccountService,
        private commonService: CommonService,
        private _fuseConfigService: FuseConfigService,
        private privilegesService: PrivilegesService,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    onEnter(evt: any, page, opt?, id?, i?) {
        if (evt.source.selected) {
            this.goTo(page, opt, id, i);
        }
    }
    ngOnInit(): void {
        this.getClientAccounts();
        this.selectorForm = this.formBuilder.group({
            selector: this.selecorControl,
        });
        this.getFolders();
        this.updateLogoImgNotifSubs = this.notificationService.updateLogoImgNotifier$.subscribe(res => { this.getFolders(); });
        this.updateFolderListNotSub = this.notificationService.updateFolderListNotifier$.subscribe(res => {
            if (res) {
                StorageUtils.setSelectedFolder(res);
            }
            this.getFolders();
        });
        this.deleteFolderNotiSub = this.notificationService.deleteFolderNotifier$.subscribe(res => {
            this.folders = this.folders.filter(folder => folder.id !== res);
            this.getFolders();
        });

        this.updateFolderSelectedNotifSub = this.notificationService.updateFolderSelectedNotifier$.subscribe(res => { this.getFolders(); });
        /*this.subscription8 = this.notificationService.adminLogoRedirection$.subscribe(res => {
          this.isAdminContext = false;
          this.selectTheFolder(StorageUtils.getSelectedFolderId());
          this.router.navigate(['folder', StorageUtils.getSelectedFolderId(), 'dashboard']);
        }); */
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }
    ngOnChanges(changes: any): void {
        if (changes && changes.context) {
            switch (changes.context.currentValue) {
                case 'administration':
                    this.context = 'administration'
                    break;
                case 'folderManagement':
                    this.context = 'folderManagement'
                    break;
            }
            this.goTo(this.context);
        }

    }
    ngOnDestroy(): void {
        this.updateLogoImgNotifSubs.unsubscribe();
        this.updateFolderListNotSub.unsubscribe();
        this.deleteFolderNotiSub.unsubscribe();
        this.updateFolderSelectedNotifSub.unsubscribe();
    }
    toggleSidebarOpen(key): void { this.fuseSidebarService.getSidebar(key).toggleOpen(); }
    getFolders() {
        this.spinner.show();
        const SelectedFolder = StorageUtils.getSelectedFolder();
        this.folderService.getAllFolders().subscribe(folders => {
            if (folders.length === 0) {
                StorageUtils.setEmptyFolder(true);
            }
            this.folders = folders.filter(folder => folder.status);
            for (let i = 0; i < this.folders.length; i++) {
                this.options[i] = this.folders[i];
            }
            let SelectedFolderId = SelectedFolder?.id;
            if ((!SelectedFolderId || !this.folders.find(folder => folder.id === SelectedFolderId)) && this.folders[0]) {
                SelectedFolderId = this.folders[0].id;
                StorageUtils.setSelectedFolder(this.folders[0]);
                this.router.navigate(['folder', StorageUtils.getSelectedFolderId(), 'view']);
            }
            if (this.options.length > 0) {
                if (SelectedFolderId) {
                    this.selectTheFolder(SelectedFolderId);
                } else {
                    this.selecorControl.setValue(null);
                    this.selectedFolder = undefined;
                }
            } else {
                StorageUtils.removeSelectedFolderId();
            }
            if ((window.location.ancestorOrigins &&
                (window.location.ancestorOrigins[0] && window.location.ancestorOrigins[0].includes('chrome-extension')))) {
                this.router.navigate(['folder', StorageUtils.getSelectedFolderId(), 'cnas-plugin']);
                this.spinner.hide();
            } else { this.spinner.hide(); }
        });
    }
    selectTheFolder(SelectedFolderId) {
        const selectedFolder: any = this.folders.find(fold => fold.id === SelectedFolderId);
        this.accountService.getAccountsClients().subscribe(res => {
            this.accountsClients = res;
            const selectedClientAccount = this.accountsClients.length > 0 ? this.accountsClients.find(res => res.id === selectedFolder.accountId) : null;
            const fastActivated = selectedClientAccount && selectedClientAccount.applicationProducts.find(product => product.applicationType === "FAST") ? true : false;
            if (selectedFolder && fastActivated) {
                if (!this.isAdminContext) {
                    this.selecorControl.setValue(selectedFolder.name);
                    StorageUtils.setSelectedFolder(selectedFolder);
                    this.folderService.getCompanyPermissions(SelectedFolderId).subscribe(res => {
                        StorageUtils.setCompanyPermission(res.modules);
                        this.checkExpireClientAccount(selectedFolder.accountId, res.modules);
                    });
                }
                this.selectedFolder = {
                    attachmentId: selectedFolder.attachmentId,
                    name: selectedFolder.name,
                    folderIndex: this.folders.indexOf(selectedFolder.id)
                };
            } else {
                StorageUtils.removeSelectedFolderId();
                StorageUtils.removeSelectedFolder();
                this.router.navigate(['profile']);
                const selectedActivatedFolder = this.getFirstActivatedClientAccountFolder();
                if (selectedActivatedFolder) {
                    this.selecorControl.setValue(selectedActivatedFolder.name);
                    StorageUtils.setSelectedFolder(selectedActivatedFolder);
                    StorageUtils.setSelectedClientAccountId(selectedActivatedFolder.accountId);
                    this.folderService.getCompanyPermissions(selectedActivatedFolder.id).subscribe(res => {
                        StorageUtils.setCompanyPermission(res.modules);
                        this.checkExpireClientAccount(selectedActivatedFolder.accountId, res.modules);
                        // todo: fix after review privilege redirection if fast disabled in client account
                        // this.router.navigate(['folder', StorageUtils.getSelectedFolderId(), 'view']);
                    });
                }
            }
            this.spinner.hide();
        });
    }
    // tslint:disable-next-line: cognitive-complexity
    goTo(page, opt?, id?, i?) {
        switch (page) {
            case 'folder':
                if (opt && opt.activated) {
                    this.spinner.show();
                    if (id) {
                        if (opt.id !== StorageUtils.getSelectedFolderId()) {
                            this.notificationService.companieChangedStatus();
                            this.selectedFolder = {
                                name: opt.name,
                                attachmentId: opt.attachmentId,
                                folderIndex: i
                            };
                            StorageUtils.setSelectedFolder(opt);
                            this.folderService.getCompanyPermissions(opt.id).subscribe(res => {
                                StorageUtils.setCompanyPermission(res.modules);
                                this.checkExpireClientAccount(opt.accountId, res.modules);
                                this.notificationService.updateFolderSelectedFunc();
                            });
                            let routeFolder = [];
                            if (this.router.url.split('/').length >= 4) {
                                routeFolder = ['folder', opt.id, this.router.url.split('/')[3].split('?')[0]];
                            } else if (this.router.url.split('/').length === 2) {
                                routeFolder = [this.router.url.split('/')[1]];
                            }
                            if (routeFolder[0] === 'folder' && routeFolder[2] === 'edit') {
                                routeFolder[2] = 'view';
                            }
                            this.router.navigate(['']).then(() => {
                                this.router.navigate(routeFolder);
                                this.privilegesService.updateNavigationSideBar();
                            });
                        }
                    }
                    setTimeout(() => {
                        this.spinner.hide();
                    }, 1000);
                } else {
                    this.nrNotifications = [];
                    if (this.autocomplete && this.autocomplete.panelOpen) {
                        this.autocomplete.closePanel();
                    }
                    this.router.navigate(['folder', 'new']);
                }
                break;
            case 'administration':
                this.spinner.show();
                this.switchToAdminContext();
                break;
            case 'folderManagement':
                this.spinner.show();
                this.fuseNavigationService.setCurrentNavigation('main');
                StorageUtils.setContext('main');
                this.isAdminContext = false;
                this.getFolders();
                setTimeout(() => {
                    this.spinner.hide();
                }, 1500);
                if (!StorageUtils.getSelectedFolderId() && this.folders.length === 0) {
                    this.router.navigate(['folder', 'new']);
                } else {
                    this.router.navigate(['folder', StorageUtils.getSelectedFolderId(), 'dashboard']);
                }
                break;
            case 'account':
                this.spinner.show();
                if (opt.id !== StorageUtils.getSelectedAccountId()) {
                    this.selectedAccountId = opt.id;
                    this.selectedAccount = opt;
                    StorageUtils.setSelectedAccountId(this.selectedAccountId);
                    let routeAdmin = [];
                    const client = 'client-account';
                    if (this.router.url.split('/').length >= 4) {
                        routeAdmin = [client, opt.id, this.router.url.split('/')[3].split('?')[0]];
                    } else if (this.router.url.split('/').length === 1) {
                        routeAdmin = [this.router.url.split('/')[1]];
                    }
                    setTimeout(() => {
                        this.spinner.hide();
                    }, 1000);
                    this.router.navigate([client]).then(() => {
                        this.router.navigate(routeAdmin);
                    });
                    this.notificationService.updateChangeAdminClient(this.selectedAccount);
                }
                break;
            default:
                break;
        }
    }
    switchToAdminContext() {
        this.isAdminContext = true;
        const folderId = StorageUtils.getSelectedFolderId();
        let accountId;
        if (folderId) {
            accountId = this.folders.find(folder => folder.id === StorageUtils.getSelectedFolderId()).accountId;
        } else {
            accountId = this.selectedAccountId;
        }
        StorageUtils.setSelectedAccountId(accountId);
        this.selectedAccount = this.clientAccounts.find(account => account.id === accountId);
        this.selecorControl.setValue(this.selectedAccount.name);
        setTimeout(() => {
            this.spinner.hide();
        }, 1000);
        this.router.navigate(['client-account', accountId, 'view']);
        this.fuseNavigationService.setCurrentNavigation('admin');
        StorageUtils.setContext('admin');
    }
    private _filter(value: string): string[] {
        const filterValue = value?.toLowerCase();
        const data = this.isAdminContext ? this.adminOptions.filter(option => option.name.toLowerCase().includes(filterValue))
            : this.options.filter(option => option.name.toLowerCase().includes(filterValue));
        data?.map(res => res.activated = this.checkFolderCLientAccountActivation(res));
        return data;
    }
    getAccronyms(opt?: any) {
        if (opt) {
            return opt.substring(0, 2).toUpperCase();
        } else {
            return null;
        }
    }
    hasImg(folder) {
        if (folder.attachmentId) {
            return this.folderLogo + '/' + folder.attachmentId;
        }
        return false;
    }
    resetFilterOptions() {
        this.selecorControl.setValue('');
        this.autocomplete.openPanel();
    }
    blur() {
        if (this.selecorControl.value === '') {
            if (!this.isAdminContext && !this.selectedFolder) {
                this.selectedFolder = this.folders.find(folder => folder.id === StorageUtils.getSelectedFolderId());
                this.selectedFolder = this.selectedFolder === null || this.selectedFolder === undefined
                    ? this.folders[0] : this.selectedFolder;
            }
            if (this.isAdminContext && !this.selectedAccount) {
                this.selectedAccount = this.clientAccounts.find(account => account.id === StorageUtils.getSelectedAccountId());
                this.selectedAccount = this.selectedAccount === null || this.selectedAccount === undefined
                    ? this.clientAccounts[0] : this.selectedAccount;
            }
            this.selecorControl.setValue(this.isAdminContext ? this.selectedAccount.name : this.selectedFolder.name);
        }
    }
    getClientAccounts() {
        this.accountService.getAccountsClients().subscribe(res => {
            StorageUtils.setClientAccount(res);
            this.accountsClients = res;
            this.filteredOptions = this.selecorControl.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
            this.clientAccounts = res.filter(account => account.role === 'ROLE_ADMIN_CLIENT');
            this.isAdminClient = this.clientAccounts.length !== 0;
            this.changeIsAdminClientEmitter.emit(this.isAdminClient);
            for (let i = 0; i < this.clientAccounts.length; i++) { this.adminOptions[i] = this.clientAccounts[i]; }
            const accountId = StorageUtils.getSelectedAccountId();
            const clientAccount = this.isAdminClient
                ? this.clientAccounts.find(account => account.id === accountId)
                : null;
            this.selectedAccount = (clientAccount === null || clientAccount === undefined) && this.isAdminClient
                ? this.clientAccounts[0]
                : clientAccount;
            this.selectedAccountId = this.selectedAccount === null || this.selectedAccount === undefined
                ? null
                : this.selectedAccount.id;
            if (this.selectedAccountId) {
                StorageUtils.setSelectedAccountId(this.selectedAccountId);
            } else { StorageUtils.removeSelectedAccountId(); }
            if (this.isAdminContext) { this.selecorControl.setValue(this.selectedAccount.name); }
            this.getLocalStorage();
        });
    }
    checkExpireClientAccount(id, modules) {
        this.accountService.getAccountClientById(id).subscribe(res => {
            this.role = res.role;
            this.changeRoleEmitter.emit(this.role);
            localStorage.setItem('roles', res.role);
            if (res.status === 'DISABLED' || res.status === 'SUSPENDED') {
                this.isAccountClientExpired = true;
            } else {
                this.isAccountClientExpired = false;
                StorageUtils.removeCompanyPermission();
                StorageUtils.setPrivilege(modules);
                this.privilegesService.updateNavigationSideBar();
            }
            this.notificationService.notifyChangePermission();
        });
    }

    hasPermission(folder) {
        if (this.accounts) {
            const courantAccount = this.accounts.find(account => account.id === folder.accountId);
            if (courantAccount) {
                return courantAccount.status !== 'DISABLED';
            }
        }
    }
    getLocalStorage() { this.accounts = StorageUtils.getClientAccount(); }
    showMessage() { this.commonService.showSnackBar('expiredAccount', this.errorSnackbar); }

    checkFolderCLientAccountActivation(folder) {
        const account = this.accountsClients.find(account => account.id === folder.accountId);
        const fastActivated = account ? account.applicationProducts.find(data => data.applicationType === "FAST") : null;

        return fastActivated ? true : false;
    }
    getFirstActivatedClientAccountFolder() {
        const clientAccountActivatedFast = this.accountsClients.find(account => account.applicationProducts.find(product => product.applicationType === "FAST"));
        if (clientAccountActivatedFast) {
            return this.folders.find(folder => folder.accountId === clientAccountActivatedFast.id);
        } else { return null }



    }
}
