@import "/src/@fuse/scss/fuse.scss";

fuse-sidebar {
  &.navbar-fuse-sidebar {
    overflow: hidden;

    &.folded:not(.unfolded) {
      navbar {
        navbar-vertical-style-1 {
          .navbar-top {
            padding: 12px 0;
            justify-content: center;

            .buttons {
              display: none;
            }

            .logo {
              .logo-icon {
                width: 32px;
                height: 32px;
              }

              .logo-text {
                display: none;
              }
            }
          }

          .navbar-scroll-container {
            .user {
              padding: 12px 0;
              height: 64px;
              min-height: 64px;
              max-height: 64px;

              .avatar-container {
                position: relative;
                top: auto;
                padding: 0;
                transform: translateX(0);
                left: auto;

                .avatar {
                  width: 40px;
                  height: 40px;
                }
              }

              .username,
              .email {
                display: none;
              }
            }

            .navbar-content {
              padding-top: 0;

              // Material 2 specific style
              .material2 {
                .nav-item {
                  .nav-link {
                    border-radius: 20px;
                    margin: 0 12px;
                    padding: 0 7px;
                  }
                }
              }
            }

            .navbar-footer {
              display: none;
            }
          }
        }
      }
    }

    &.folded.right-positioned:not(.unfolded) {
      navbar {
        navbar-vertical-style-1 {
          .navbar-content {
            .material2 {
              .nav-item {
                .nav-link {
                  margin: 0 auto;
                  // background-color: red;
                  margin-right: 0;
                  padding-right: 4px;
                }

                .nav-link.active {
                  border-left: none;
                  border-right: 4px solid rgb(245, 67, 55);
                  transition: border-width 0.2s linear 0s;
                }
              }

              .nav-item.open {
                .nav-link {
                  border-left: none !important;
                  border-right: 4px solid rgb(245, 67, 55);
                  transition: border-width 0.2s linear 0s;
                }
              }
            }
          }
        }
      }
    }
  }

  @keyframes jump {
    0%, 100% {
      transform: translateY(0);
    }
    20%, 60% {
      transform: translateY(-30px);
    }
    40%, 80% {
      transform: translateY(0);
    }
  }
  
  .logo {
    width: 100px; // ajustez selon vos besoins
    height: 100px; // ajustez selon vos besoins
    display: inline-block; // pour s'assurer que le logo se comporte correctement
  
    &:hover {
      animation: jump 1s ease-out 0s 3; // animation de 1 seconde, ease-out, 0 délai, répétée 3 fois
    }
  }
}

navbar {
  &.vertical-style-1 {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;

    &.right-navbar {
      .toggle-sidebar-opened {
        mat-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  navbar-vertical-style-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .navbar-top {
      display: flex;
      flex-direction: row;
      flex: 1 0 auto;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 120px;
      max-height: 120px;
      height: 120px;
      padding: 12px 12px 12px 20px;

      // @include media-breakpoint("xs") {
      //     min-height: 120px;
      //     max-height: 120px;
      //     height: 120px;
      // }

      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        cursor: pointer;

        .logo-icon {
          width: 50px;
          height: 50px;
        }

        .logo-text {
          font-size: 15px;
          letter-spacing: 0.4px;
          line-height: normal;
          color: white !important;
          font-weight: 780;
          margin-top: 6px;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
      }
    }

    .navbar-scroll-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      // background: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%),
      // linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%);

      background-repeat: no-repeat;
      background-size: 100% 40px, 100% 10px;
      background-attachment: local, scroll;

      .user {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 136px;
        min-height: 136px;
        max-height: 136px;
        padding: 24px 0 64px 0;

        .avatar-container {
          position: absolute;
          top: 92px;
          border-radius: 50%;
          padding: 8px;
          transform: translateX(-50%);
          left: 50%;

          .avatar {
            width: 72px;
            height: 72px;
            margin: 0;
          }
        }
      }

      .navbar-content {
        flex: 1 1 auto;
        // padding-top: 32px;
      }

      .navbar-footer {
        text-align: center;
        padding: 6px 3px;
        height: 70px;

        a {
          font-size: 13px !important;
          font-family: monospace !important;
          color: #ffffff87 !important;
          width: 100%;
        }

        a:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}
