import { ORDER_VOUCHERS_DATATABLE_CONFIG } from './../../../../main/pages/folder/orders-vouchers/order-vouchers-datatable-config';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CLIENT_DATATABLE_CONFIG } from 'app/main/pages/folder/clients/view-all-clients/clients-datatable-config';
import { INVOICE_DATATABLE_CONFIG } from 'app/main/pages/folder/invoices/view-all-invoice/invoice-datatable-config';
import { PRICE_LIST_DATATABLE_CONFIG } from 'app/main/pages/folder/prices/view-all-prices/view-all-prices-datatable-config';
import { PRODUCTS_DATATABLE_CONFIG } from 'app/main/pages/folder/products/view-all-products/products-datatable-config';
import { QUOTE_DATATABLE_CONFIG } from 'app/main/pages/folder/quotes/view-all-quote/quote-datatable-config';
import { CASHING_DATATABLE_CONFIG } from 'app/main/pages/folder/cashings/view-all-cashing/cashing-datatable-config';
import { DISBURSMENTS_DATATABLE_CONFIG } from 'app/main/pages/folder/disbursments/view-all-disbursments/disbursment-datatable-config';
import { locale as francais } from './i18n/fr';
import { locale as arabe } from './i18n/dz';


import { ClientsService } from 'app/services/clients.service';
import { DataStorageService } from 'app/services/data.service';
import { DeliveryNoteService } from 'app/services/delivery-note.service';
import { FamilyClientService } from 'app/services/family-client.service';
import { InvoiceService } from 'app/services/invoice.service';
import { PriceListService } from 'app/services/price-list.service';
import { ProductFamilyService } from 'app/services/product-family.service';
import { ProductsService } from 'app/services/products.service';
import { QuoteService } from 'app/services/quote.service';
import { StorageUtils } from 'app/shared/storage-utils';
import { DELIVERY_DATATABLE_CONFIG } from 'app/main/pages/folder/delivery-notes/view-all-deliveries-note/delivery-note-datatable.config';
import { CashingService } from 'app/services/cashing.service';
import { DisbursmentService } from 'app/services/disbursment.service';
import { Subscription, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FundingBoxService } from 'app/services/funding-box.service';
import { ProvidersService } from 'app/services/providers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { PrivilegesService } from 'app/services/privileges.service';
import { FolderService } from 'app/services/folder.service';
import { NotifiersService } from 'app/services/notifiers.service';
import { WebsocketService } from 'app/services/websocket.service';
import { CommonService } from 'app/services/common.service';
import { OrdersVouchersService } from 'app/services/orders-vouchers.service';




@Component({
  selector: 'app-context-offline',
  templateUrl: './context-offline.component.html',
  styleUrls: ['./context-offline.component.scss']
})
export class ContextOfflineComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: any) {
    if (!this.networkStatus) {
      event.returnValue = 'Are you sure you want to leave this page?';
    }
  }

  configFa = { ...INVOICE_DATATABLE_CONFIG };
  configProduct = { ...PRODUCTS_DATATABLE_CONFIG };
  configClients = { ...CLIENT_DATATABLE_CONFIG };
  configQuot = { ...QUOTE_DATATABLE_CONFIG };
  configOrderVouchers = { ...ORDER_VOUCHERS_DATATABLE_CONFIG };
  configDelivery = { ...DELIVERY_DATATABLE_CONFIG };
  pageInvoices = 0;
  invoices: any[];

  started = false

  pageProducts = 0;
  products: any[];

  pageClients = 0;
  clients: any[];
  clientFamilies: any[];

  pageQuots = 0;
  quots: any[];

  pageOrderVouchers = 0;
  orderVouchers: any[];

  pageDelivery = 0;
  deliveryNotes: any[];

  configCacheReceip = { ...CASHING_DATATABLE_CONFIG };
  cachingsRec: any[];
  pageCachings = 0;

  configDisbursments = { ...DISBURSMENTS_DATATABLE_CONFIG };
  disbursments: any[];
  pageDisbursments = 0;
  networkStatus$: Subscription = Subscription.EMPTY;

  pageFundingBox = 0;
  fundingBox: any[];
  fundingBoxTotalItems = 0;

  pageProviders = 0;
  providers: any[];
  providersTotalItems = 0;

  productFamilyTotalItems = 0;
  clientFamilyTotalItems = 0;
  pricesTotalItems = 0;

  pageProductClientFamiliesPriseList = 0;
  productClientFamiliesPriseList: any[];
  productClientFamiliesPriseListTotalItems = 0;

  actions = [];

  docsPageCount = 10;

  // Progress values 
  clientsProgressValue = 0;
  productsProgressValue = 0;
  clientFamiliesProgressValue = 0;
  productFamiliesProgressValue = 0;
  pricesProgressValue = 0;
  invoicesProgressValue = 0;
  quotesProgressValue = 0;
  orderVouchersProgressValue = 0;
  deliveryNotesProgressValue = 0;
  cashReseiptProgressValue = 0;
  disbursmentsProgressValue = 0;
  fundingBoxsProgressValue = 0;
  providersProgressValue = 0;
  productClientFamiliesPriseListProgressValue = 0;

  // Progress uploaded values 
  clientsUploadedValue = 0;
  productsUploadedValue = 0;
  clientFamiliesUploadedValue = 0;
  productFamiliesUploadedValue = 0;
  pricesUploadedValue = 0;
  invoicesUploadedValue = 0;
  quotesUploadedValue = 0;
  orderVoucherUploadedValue = 0;
  deliveryNotesUploadedValue = 0;
  cashReseiptUploadedValue = 0;
  disbursmentsUploadedValue = 0;
  fundingBoxsUploadedValue = 0;
  providersUploadedValue = 0;
  productClientFamiliesPriseListUploadedValue = 0;

  // Progress sync values 
  clientsProgressSyncValue: number = 0;
  productsProgressSyncValue: number = 0;
  clientFamiliesProgressSyncValue: number = 0;
  productFamiliesProgressSyncValue: number = 0;
  pricesProgressSyncValue: number = 0;
  invoicesProgressSyncValue: number = 0;
  quotesProgressSyncValue: number = 0;
  deliveryNotesProgressSyncValue: number = 0;
  cashReseiptProgressSyncValue: number = 0;
  disbursmentsProgressSyncValue: number = 0;
  fundingBoxsProgressSyncValue: number = 0;
  providersProgressSyncValue: number = 0;
  productClientFamiliesPriseListProgressSyncValue: number = 0;

  // Progress calculated sync values 
  clientsSyncValue: number = 0;
  productsSyncValue: number = 0;
  clientFamiliesSyncValue: number = 0;
  productFamiliesSyncValue: number = 0;
  pricesSyncValue: number = 0;
  invoicesSyncValue: number = 0;
  quotesSyncValue: number = 0;
  deliveryNotesSyncValue: number = 0;
  cashReseiptsSyncValue: number = 0;
  disbursmentsSyncValue: number = 0;
  fundingBoxSyncValue: number = 0;
  providersSyncValue: number = 0;
  productClientFamiliesPriseListsSyncValue: number = 0;

  // Total added element values 
  allClientsAdded: number = 0;
  allProductsAdded: number = 0;
  allClientFamiliesAdded: number = 0;
  allProductFamiliesAdded: number = 0;
  allPricesAdded: number = 0;
  allInvoicesAdded: number = 0;
  allQuotesAdded: number = 0;
  allDeliveryNotesAdded: number = 0;
  allCashReseiptsAdded: number = 0;
  allDisbursmentsAdded: number = 0;
  allFundingBoxsAdded: number = 0;
  allProvidersAdded: number = 0;
  allProductClientFamiliesPriseListsAdded: number = 0;

  addedModulesTables = [
    'devisAdded',
    'productAdded',
    'blAdded',
    'factureAdded',
    'cashingsAdded',
    'disbursementsAdded'
  ];
  addedTables = [
    'clientsAdded',
    'productAdded',
    'clientsFamilyAdded',
    'productFamilyAdded',
    'tarifAdded',
    'devisAdded',
    'blAdded',
    'factureAdded',
    'cashingsAdded',
    'disbursementsAdded',
  ];
  unploadedTables = [
    'facture',
    'data',
    'clients',
    'productFamily',
    'clientsFamily',
    'tarif',
    'devis',
    'bl',
    'cachings',
    'disbursements',
    'cash-desks',
    'providers',
    'productClientFamiliesPriseList',
    'uploadedDataInfos',
    'localStorageShared',
  ]
  companieChanged$: Subscription;
  networkStatus;

  // Privileges modules
  clientsPrivileges: any;
  productsPrivileges: any;
  clientFamiliesPrivileges: any;
  productFamiliesPrivileges: any;
  pricesPrivileges: any;
  invoicesPrivileges: any;
  quotesPrivileges: any;
  // TODO: fix on add privileges
  orderVouchersPrivileges: any = { canWrite: true };
  deliveryNotesPrivileges: any;
  cashReseiptPrivileges: any;
  disbursmentsPrivileges: any;
  fundingBoxsPrivileges: any;
  providersPrivileges: any;

  // Errors
  clientsError;
  productsError;
  clientFamiliesError;
  productFamiliesError;
  pricesError;
  invoicesError;
  quotesError;
  orderVoucherError;
  deliveryNotesError;
  cashReseiptError;
  disbursmentsError;
  fundingBoxsError;
  providersError;
  productClientFamiliesPriseListError;

  saving = false;
  uploadedDataInfos: any;

  uploadData = 'offlineContext.tooltips.upload';
  downloadData = 'offlineContext.tooltips.download';
  infos = 'offlineContext.tooltips.infos';


  constructor(private invoiceService: InvoiceService,
    private webSocketService: WebsocketService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private productService: ProductsService, private cacheStorageService: DataStorageService,
    private clientService: ClientsService, private productFamilyService: ProductFamilyService,
    private clientFamilyService: FamilyClientService, private priceService: PriceListService,
    private quoteService: QuoteService, private deliveryNoteService: DeliveryNoteService,
    private cashingService: CashingService, private disbursmentService: DisbursmentService,
    private fbService: FundingBoxService, private providersService: ProvidersService,
    private privilegesService: PrivilegesService, private folderService: FolderService,
    private notifiersService: NotifiersService, private commonService: CommonService,
    private orderVouchersService: OrdersVouchersService,) {
    fuseTranslationLoaderService.loadTranslations(francais, arabe);

    this.companieChanged$ = this.notifiersService.companieChangedNotifier$.subscribe(res => {
      this.getPermissions();
    })
  }

  ngOnDestroy(): void {
    this.networkStatus$?.unsubscribe();
    this.companieChanged$?.unsubscribe();
  }

  ngOnInit() {
    this.commonService.showSnackBar('offlineContext.messages.warningMessage', 'info-message-snackbar');
    const selectedFolderId = StorageUtils.getSelectedFolderId();
    if (selectedFolderId) {
      this.getPermissions();
    }
  }

  getPermissions() {
    this.folderService.getCompanyPermissions(StorageUtils.getSelectedFolderId()).subscribe(res => {
      StorageUtils.setPrivilege(res.modules);
      this.managePrivileges();
      this.checkNetworkStatus();
    })
  }
  checkNetworkStatus() {
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
        if (!status) {
          this.cacheStorageService.getItem('selectedFolder', 'localStorageShared', true).then(data => {
            StorageUtils.setSelectedFolder(data);

          })
          const authToken = StorageUtils.getAuthToken();
          if (authToken) {
            this.webSocketService.connect();
          }
        } else {
          this.saving = false;
          this.commonService.showSnackBar('offlineContext.messages.refreshWarningMessage', 'info-message-snackbar');
        }
      });
  }

  managePrivileges() {
    this.clientsPrivileges = this.privilegesService.setPrivilegesByPermission('CLIENT')
    this.productsPrivileges = this.privilegesService.setPrivilegesByPermission('PRODUCT')
    this.clientFamiliesPrivileges = this.privilegesService.setPrivilegesByPermission('CLIENT_FAMILY')
    this.productFamiliesPrivileges = this.privilegesService.setPrivilegesByPermission('PRODUCT_FAMILY')
    this.pricesPrivileges = this.privilegesService.setPrivilegesByPermission('PRICE_LIST')
    this.invoicesPrivileges = this.privilegesService.setPrivilegesByPermission('INVOICE')
    this.quotesPrivileges = this.privilegesService.setPrivilegesByPermission('QUOTE')
    this.saveAllOrderVouchers = this.privilegesService.setPrivilegesByPermission('PURCHASE_ORDERS')
    this.deliveryNotesPrivileges = this.privilegesService.setPrivilegesByPermission('DELIVERY_NOTE')
    this.cashReseiptPrivileges = this.privilegesService.setPrivilegesByPermission('CASH_RECEIPT')
    this.disbursmentsPrivileges = this.privilegesService.setPrivilegesByPermission('DISBURSEMENT')
    this.fundingBoxsPrivileges = this.privilegesService.setPrivilegesByPermission('CASH_DESK')
    this.providersPrivileges = this.privilegesService.setPrivilegesByPermission('PROVIDER')
  }

  async startSaving() {
    this.saving = true;
    this.started = true;
    this.invoices = [];
    this.products = [];
    this.clients = [];
    this.quots = [];
    this.deliveryNotes = [];
    this.cachingsRec = [];
    this.disbursments = [];
    this.fundingBox = [];
    this.providers = [];
    this.productClientFamiliesPriseList = [];
    this.clientsProgressValue = 0;
    this.productsProgressValue = 0;
    this.clientFamiliesProgressValue = 0;
    this.productFamiliesProgressValue = 0;
    this.pricesProgressValue = 0;
    this.invoicesProgressValue = 0;
    this.quotesProgressValue = 0;
    this.deliveryNotesProgressValue = 0;
    this.cashReseiptProgressValue = 0;
    this.disbursmentsProgressValue = 0;
    this.fundingBoxsProgressValue = 0;
    this.providersProgressValue = 0;

    // Reset pages
    this.pageClients = 0;
    this.pageProducts = 0;
    this.pageInvoices = 0;
    this.pageQuots = 0;
    this.pageDelivery = 0;
    this.pageCachings = 0;
    this.pageDisbursments = 0;
    this.pageFundingBox = 0;
    this.pageProviders = 0;
    this.pageProductClientFamiliesPriseList = 0;

    // Reset progressing values 
    this.productClientFamiliesPriseListProgressValue = 0;
    this.clientsError = false
    this.productsError = false
    this.clientFamiliesError = false
    this.productFamiliesError = false
    this.pricesError = false
    this.invoicesError = false
    this.quotesError = false
    this.deliveryNotesError = false
    this.cashReseiptError = false
    this.disbursmentsError = false
    this.fundingBoxsError = false
    this.providersError = false
    this.productClientFamiliesPriseListError = false

    // Clear database
    this.clearUploadedData().then(() => {
      this.saveAllStorageshared();
      // Upload data
      if (this.clientsPrivileges?.canWrite) { this.saveClients(this.pageClients); }
      if (this.productsPrivileges?.canWrite) { this.saveProducts(this.pageProducts); }
      if (this.clientFamiliesPrivileges?.canWrite) { this.saveAllFamilyClients(); }
      if (this.productFamiliesPrivileges?.canWrite) { this.saveAllFamilyProducts(); }
      if (this.pricesPrivileges?.canWrite) { this.saveAllPrices(); }
      if (this.invoicesPrivileges?.canWrite) { this.saveFacture(this.pageInvoices); }
      if (this.quotesPrivileges?.canWrite) { this.saveAllQuotes(this.pageQuots); }
      if (this.orderVouchersPrivileges?.canWrite) { this.saveAllOrderVouchers(this.pageOrderVouchers); }
      if (this.deliveryNotesPrivileges?.canWrite) { this.saveAllDeliveryNotes(this.pageDelivery); }
      if (this.cashReseiptPrivileges?.canWrite) { this.saveAllCacheReceip(this.pageCachings) }
      if (this.disbursmentsPrivileges?.canWrite) { this.saveAllDisbursments(this.pageDisbursments); }
      if (this.fundingBoxsPrivileges?.canWrite) { this.saveFundingBox(this.pageFundingBox); }
      if (this.providersPrivileges?.canWrite) { this.saveAllProviders(this.pageProviders) }
      if (this.pricesPrivileges?.canWrite) { this.saveProductClientFamiliesPriseList(this.pageProductClientFamiliesPriseList) }
    })
  }

  saveAllStorageshared() {
    this.cacheStorageService.addItem([StorageUtils.getSelectedFolder()], 'localStorageShared', 'selectedFolder');
    this.cacheStorageService.addItem([StorageUtils.getWilayas()], 'localStorageShared', 'wilayas');
    this.cacheStorageService.addItem([StorageUtils.getCommunesByWilaya()], 'localStorageShared', 'communesByWilaya');
    this.cacheStorageService.addItem([StorageUtils.getCommunes()], 'localStorageShared', 'communes');
    this.cacheStorageService.addItem([StorageUtils.getActivities()], 'localStorageShared', 'activities');
    this.cacheStorageService.addItem([StorageUtils.getUnitMeasure()], 'localStorageShared', 'unitMeasure');
    this.cacheStorageService.addItem([StorageUtils.getPayementTerm()], 'localStorageShared', 'payementTerms');
    this.cacheStorageService.addItem([StorageUtils.getPrivilege()], 'localStorageShared', 'privilege');
    this.cacheStorageService.addItem([StorageUtils.getTva()], 'localStorageShared', 'tva');
    this.cacheStorageService.addItem([StorageUtils.getLegalForms()], 'localStorageShared', 'LegalForms');
    this.cacheStorageService.addItem([StorageUtils.getClientAccount()], 'localStorageShared', 'accounts');
    this.cacheStorageService.addItem([StorageUtils.getUser()], 'localStorageShared', 'user');
  }

  // Get data
  saveProducts(pageNext) {
    const { pageCount, pageIndex, sorts } = this.configProduct;
    this.productService.getAllProducts(StorageUtils.getSelectedFolderId(), sorts[0].prop, sorts[0].direction, pageNext,
      pageCount, {}, null, null, true).subscribe(data => {
        data.body.forEach(element => {
          this.products.push(element)
        });
        this.configProduct.totalItems = data.headers.get('X-Total-Count') ? Number(data.headers.get('X-Total-Count')) : 0;
        if (this.configProduct.totalItems === 0) {
          this.productsProgressValue = 100;
        }
        this.cacheStorageService.addItem(data.body, 'product')
        if (this.productsProgressValue !== 100) {
          this.productsUploadedValue = this.products.length;
          this.productsProgressValue = (this.products.length * 100) / this.configProduct.totalItems;
        }
        if (this.productsProgressValue === 100) {
          this.saveUploadedDataInfos();
        }
        if (this.products.length < this.configProduct.totalItems) {
          this.pageProducts++
          this.saveProducts(this.pageProducts)
        }
      }, (err: any) => {
        console.log('Products error', err);
        this.productsError = true;
        this.saving = false;
        this.saveUploadedDataInfos();
      })
  }

  saveClients(pageNext) {
    const { pageCount, sorts } = this.configClients;
    this.clientService
      .getClients(StorageUtils.getSelectedFolderId(), pageNext, pageCount, sorts, null, null, true)
      .subscribe(
        (data) => {
          data.body.forEach(element => {
            this.clients.push(element)
          });
          this.configClients.totalItems = data.headers.get('X-Total-Count') ? Number(data.headers.get('X-Total-Count')) : 0;
          if (this.configClients.totalItems === 0) {
            this.clientsProgressValue = 100;
          }
          this.cacheStorageService.addItem(data.body, 'clients');
          if (this.clientsProgressValue !== 100) {
            this.clientsUploadedValue = this.clients.length;
            this.clientsProgressValue = (this.clients.length * 100) / this.configClients.totalItems;
          }
          if (this.clientsProgressValue === 100) {
            this.saveUploadedDataInfos();
          }
          if (this.clients.length < this.configClients.totalItems) {
            this.pageClients++
            this.saveClients(this.pageClients)
          }
        }, (err: any) => {
          console.log('Client error', err);
          this.clientsError = true;
          this.saving = false;
          this.saveUploadedDataInfos();
        })
  }

  saveAllFamilyProducts() {
    this.productFamilyService.getAllProductFamily(StorageUtils.getSelectedFolderId(), 'id', 'desc', 0, 2000, null, true)
      .subscribe((res: any) => {
        this.cacheStorageService.addItem(res.body, 'productFamily')
        this.productFamilyTotalItems = res.body.length;
        this.productFamiliesUploadedValue = res.body.length;
        this.productFamiliesProgressValue = 100;
        this.saveUploadedDataInfos();
      }, (err: any) => {
        console.log('Products Family error', err);
        this.productFamiliesError = true;
        this.saving = false;
        this.saveUploadedDataInfos();
      })
  }

  saveAllFamilyClients() {
    this.clientFamilyService.getAllFamilyClientWithoutPagination(StorageUtils.getSelectedFolderId(), null, true)
      .subscribe((res: any) => {
        this.cacheStorageService.addItem(res.body, 'clientsFamily');
        this.clientFamilyTotalItems = res.body.length;
        this.clientFamiliesUploadedValue = res.body.length;
        this.clientFamiliesProgressValue = 100;
        this.saveUploadedDataInfos();
      }, (err: any) => {
        console.log('Client Family error', err);
        this.clientFamiliesError = true;
        this.saving = false;
        this.saveUploadedDataInfos();
      })
  }

  saveAllPrices() {
    // eslint-disable-next-line prefer-const
    let config = { ...PRICE_LIST_DATATABLE_CONFIG };
    const { pageCount, pageIndex, sorts } = config;
    this.priceService.getPrices(StorageUtils.getSelectedFolderId(), pageIndex, 2000, sorts, null, null, true).subscribe(res => {
      this.cacheStorageService.addItem(res.body, 'tarif');
      this.pricesTotalItems = res.body.length;
      this.pricesUploadedValue = res.body.length;
      this.pricesProgressValue = 100;
      this.saveUploadedDataInfos();
    }, (err: any) => {
      console.log('Prices error', err);
      this.pricesError = true;
      this.saving = false;
      this.saveUploadedDataInfos();
    });
  }

  saveAllQuotes(pageNext) {
    const { pageCount, sorts } = this.configQuot;
    this.quoteService.getAllQuotes(StorageUtils.getSelectedFolderId(), sorts[0].prop, sorts[0].direction,
      pageNext, this.docsPageCount, null, null, null, null, true).subscribe(response => {
        response.body.forEach(element => {
          this.quots.push(element)
        });
        this.configQuot.totalItems = response.headers.get('X-Total-Count') ? Number(response.headers.get('X-Total-Count')) : 0;
        if (this.configQuot.totalItems === 0) {
          this.quotesProgressValue = 100;
        }
        this.cacheStorageService.addItem(response.body, 'devis');
        if (this.quotesProgressValue !== 100) {
          this.quotesUploadedValue = this.quots.length;
          this.quotesProgressValue = (this.quots.length * 100) / this.configQuot.totalItems;
        }
        if (this.quotesProgressValue === 100) {
          this.saveUploadedDataInfos();
        }
        if (this.quots.length < this.configQuot.totalItems) {
          this.pageQuots++
          this.saveAllQuotes(this.pageQuots)
        }
      }, (err: any) => {
        console.log('Quotes error', err);
        this.quotesError = true;
        this.saving = false;
        this.saveUploadedDataInfos();
      })
  }

  saveAllOrderVouchers(pageNext) {
    const { pageCount, sorts } = this.configOrderVouchers;
    this.orderVouchersService.getAllOrderVouchers(StorageUtils.getSelectedFolderId(), sorts[0].prop, sorts[0].direction,
      pageNext, this.docsPageCount, null, null).subscribe(response => {
        response.body.forEach(element => {
          this.orderVouchers.push(element)
        });
        this.configOrderVouchers.totalItems = response.headers.get('X-Total-Count') ? Number(response.headers.get('X-Total-Count')) : 0;
        if (this.configOrderVouchers.totalItems === 0) {
          this.orderVouchersProgressValue = 100;
        }
        this.cacheStorageService.addItem(response.body, 'purchase-orders');
        if (this.orderVouchersProgressValue !== 100) {
          this.orderVoucherUploadedValue = this.orderVouchers.length;
          this.orderVouchersProgressValue = (this.orderVouchers.length * 100) / this.configOrderVouchers.totalItems;
        }
        if (this.orderVouchersProgressValue === 100) {
          this.saveUploadedDataInfos();
        }
        if (this.orderVouchers.length < this.configOrderVouchers.totalItems) {
          this.pageOrderVouchers++
          this.saveAllOrderVouchers(this.pageOrderVouchers)
        }
      }, (err: any) => {
        console.log('Purchase order error', err);
        this.orderVoucherError = true;
        this.saving = false;
        this.saveUploadedDataInfos();
      })
  }

  saveAllDeliveryNotes(pageNext) {
    const { pageCount, pageIndex, sorts } = this.configDelivery;
    this.deliveryNoteService.getAllDeliveryNote(StorageUtils.getSelectedFolderId(), sorts[0].prop, sorts[0].direction, pageNext,
      this.docsPageCount, null, null, null, null, null, true).subscribe(response => {
        response.body.forEach(element => {
          this.deliveryNotes.push(element);
        });
        this.configDelivery.totalItems = response.headers.get('X-Total-Count') ? Number(response.headers.get('X-Total-Count')) : 0;
        if (this.configDelivery.totalItems === 0) {
          this.deliveryNotesProgressValue = 100;
        }
        this.cacheStorageService.addItem(response.body, 'bl');
        if (this.deliveryNotesProgressValue !== 100) {
          this.deliveryNotesUploadedValue = this.deliveryNotes.length;
          this.deliveryNotesProgressValue = (this.deliveryNotes.length * 100) / this.configDelivery.totalItems;
        }
        if (this.deliveryNotesProgressValue === 100) {
          this.saveUploadedDataInfos();
        }
        if (this.deliveryNotes.length < this.configDelivery.totalItems) {
          this.pageDelivery++
          this.saveAllDeliveryNotes(this.pageDelivery)
        }
      }, (err: any) => {
        console.log('Delivery notes error', err);
        this.deliveryNotesError = true;
        this.saving = false;
      })
  }

  saveFacture(pageNext) {
    const { pageCount, pageIndex, sorts } = this.configFa;
    this.invoiceService.getAllInvoice(StorageUtils.getSelectedFolderId(), pageNext, this.docsPageCount, sorts, null, null,
      null, null, null, null, null, null, true).subscribe((res) => {
        this.configFa.totalItems = +res.headers.get("X-Total-Count");
        if (this.configFa.totalItems === 0) {
          this.invoicesProgressValue = 100;
        }
        res.body.forEach(element => {
          this.invoices.push(element)
        });
        this.cacheStorageService.addItem(res.body, 'facture');
        if (this.invoicesProgressValue !== 100) {
          this.invoicesUploadedValue = this.invoices.length;
          this.invoicesProgressValue = (this.invoices.length * 100) / this.configFa.totalItems;
        }
        if (this.invoicesProgressValue === 100) {
          this.saveUploadedDataInfos();
        }
        if (this.invoices.length < this.configFa.totalItems) {
          this.pageInvoices++
          this.saveFacture(this.pageInvoices)
        }
      }, (err: any) => {
        console.log('Invoices error', err);
        this.invoicesError = true;
        this.saving = false;
        this.saveUploadedDataInfos();
      }
      );
  }

  saveAllCacheReceip(pageNext) {
    const { pageCount, pageIndex, sorts } = this.configCacheReceip;
    this.cashingService.getCashings(StorageUtils.getSelectedFolderId(), pageNext, pageCount, sorts, null, null, true).subscribe((res) => {
      this.configCacheReceip.totalItems = +res.headers.get("X-Total-Count");
      if (this.configCacheReceip.totalItems === 0) {
        this.cashReseiptProgressValue = 100;
      }
      res.body.forEach(element => {
        this.cachingsRec.push(element)
      });
      this.cacheStorageService.addItem(res.body, 'cachings');
      if (this.cashReseiptProgressValue !== 100) {
        this.cashReseiptUploadedValue = this.cachingsRec.length;
        this.cashReseiptProgressValue = (this.cachingsRec.length * 100) / this.configCacheReceip.totalItems;
      }
      if (this.cashReseiptProgressValue === 100) {
        this.saveUploadedDataInfos();
      }
      if (this.cachingsRec.length < this.configCacheReceip.totalItems) {
        this.pageCachings++
        this.saveAllCacheReceip(this.pageCachings)
      }
    }, (err: any) => {
      console.log('Cash receipt error', err);
      this.cashReseiptError = true;
      this.saving = false;
      this.saveUploadedDataInfos();
    }
    );
  }

  saveAllDisbursments(pageNext) {
    const { pageCount, pageIndex, sorts } = this.configDisbursments;
    this.disbursmentService.getAllDisbursments(StorageUtils.getSelectedFolderId(), pageNext, pageCount, sorts, null, null, true).subscribe((res) => {
      this.configDisbursments.totalItems = +res.headers.get("X-Total-Count");
      if (this.configDisbursments.totalItems === 0) {
        this.disbursmentsProgressValue = 100;
      }
      res.body.forEach(element => {
        this.disbursments.push(element)
      });
      this.cacheStorageService.addItem(res.body, 'disbursements');
      if (this.disbursmentsProgressValue !== 100) {
        this.disbursmentsUploadedValue = this.disbursments.length;
        this.disbursmentsProgressValue = (this.disbursments.length * 100) / this.configDisbursments.totalItems;
      }
      if (this.disbursmentsProgressValue === 100) {
        this.saveUploadedDataInfos();
      }
      if (this.disbursments.length < this.configDisbursments.totalItems) {
        this.pageDisbursments++
        this.saveAllDisbursments(this.pageDisbursments)
      }
    }, (err: any) => {
      console.log('Disbursement error', err);
      this.disbursmentsError = true;
      this.saving = false;
      this.saveUploadedDataInfos();
    }
    );
  }

  saveFundingBox(pageNext) {
    this.fbService.getAllBoxes(StorageUtils.getSelectedFolderId(), 'id', 'desc', pageNext, 10, null, null, true).subscribe((res) => {
      this.fundingBoxTotalItems = +res.headers.get("X-Total-Count");
      if (this.fundingBoxTotalItems === 0) {
        this.fundingBoxsProgressValue = 100;
      }
      res.body.forEach(element => {
        this.fundingBox.push(element)
      });
      this.cacheStorageService.addItem(res.body, 'cash-desks');
      if (this.fundingBoxsProgressValue !== 100) {
        this.fundingBoxsUploadedValue = this.fundingBox.length;
        this.fundingBoxsProgressValue = (this.fundingBox.length * 100) / this.fundingBoxTotalItems;
      }
      if (this.fundingBoxsProgressValue === 100) {
        this.saveUploadedDataInfos();
      }
      if (this.fundingBox.length < this.fundingBoxTotalItems) {
        this.pageFundingBox++
        this.saveFundingBox(this.pageFundingBox)
      }
    }, (err: any) => {
      console.log('Funding boxe error', err);
      this.fundingBoxsError = true;
      this.saving = false;
      this.saveUploadedDataInfos();
    }
    );
  }

  saveAllProviders(pageNext) {
    this.providersService.getProviders(StorageUtils.getSelectedFolderId(), pageNext, 10, [{ prop: 'id', direction: 'desc' }], null, null, true)
      .subscribe((res) => {
        this.providersTotalItems = +res.headers.get("X-Total-Count");
        if (this.providersTotalItems === 0) {
          this.providersProgressValue = 100;
        }
        res.body.forEach(element => {
          this.providers.push(element);
        });
        this.cacheStorageService.addItem(res.body, 'providers');
        if (this.providersProgressValue !== 100) {
          this.providersUploadedValue = this.providers.length;
          this.providersProgressValue = (this.providers.length * 100) / this.providersTotalItems;
        }
        if (this.providersProgressValue === 100) {
          this.saveUploadedDataInfos();
        }
        if (this.providers.length < this.providersTotalItems) {
          this.pageProviders++
          this.saveAllProviders(this.pageProviders)
        }
      }, (err: any) => {
        console.log('Providers error', err);
        this.providersError = true;
        this.saving = false;
        this.saveUploadedDataInfos();
      }
      );
  }

  saveProductClientFamiliesPriseList(pageNext) {
    this.priceService.getProductClientFamiliesPriseList(StorageUtils.getSelectedFolderId(), pageNext, 10, [{ prop: 'id', direction: 'desc' }],)
      .subscribe((res) => {
        this.productClientFamiliesPriseListTotalItems = +res.headers.get("X-Total-Count");
        if (this.productClientFamiliesPriseListTotalItems === 0) {
          this.productClientFamiliesPriseListProgressValue = 100;
        }
        res.body.forEach(element => {
          this.productClientFamiliesPriseList.push(element);
        });
        this.cacheStorageService.addItem(res.body, 'productClientFamiliesPriseList');
        if (this.productClientFamiliesPriseListProgressValue !== 100) {
          this.productClientFamiliesPriseListUploadedValue = this.productClientFamiliesPriseList.length;
          this.productClientFamiliesPriseListProgressValue = (this.productClientFamiliesPriseList.length * 100) / this.productClientFamiliesPriseListTotalItems;
        }
        if (this.productClientFamiliesPriseListProgressValue === 100) {
          this.saveUploadedDataInfos();
        }
        if (this.productClientFamiliesPriseList.length < this.productClientFamiliesPriseListTotalItems) {
          this.pageProductClientFamiliesPriseList++
          this.saveProductClientFamiliesPriseList(this.pageProductClientFamiliesPriseList)
        }
      }, (err: any) => {
        console.log('ProductClientFamiliesPriseList error', err);
        this.productClientFamiliesPriseListError = true;
        this.saving = false;
        this.saveUploadedDataInfos();
      }
      );
  }


  // Save data
  async saveClientsAdded(): Promise<void> {
    const clientsAdded = await this.cacheStorageService.getAllItems('clientsAdded');
    this.allClientsAdded = clientsAdded && clientsAdded.length > 0 ? clientsAdded.length : 0;
    if (this.allClientsAdded === 0) {
      this.clientsProgressSyncValue = 100;
    }
    return new Promise<void>(async (resolve1, reject) => {
      if (clientsAdded && clientsAdded.length > 0) {
        // Use a for...of loop to ensure sequential execution
        for (const element of clientsAdded) {
          const el = {
            ...element,
            id: null
          };
          el.clientFamilies = [];
          const clientFD = new FormData();
          const clientFile = new File([JSON.stringify(el)], "client.json", {
            type: "application/json",
          });
          clientFD.append("client", clientFile);
          clientFD.append("client_logo", null);

          // eslint-disable-next-line no-async-promise-executor
          await new Promise<void>(async (resolve2, reject) => {
            this.clientService.createUser(StorageUtils.getSelectedFolderId(), clientFD).subscribe(async client => {
              client.offlineId = element.id;
              this.clientsSyncValue++;
              if (this.clientsProgressSyncValue !== 100) {
                this.clientsProgressSyncValue = (this.clientsSyncValue * 100) / this.allClientsAdded;
              }
              try {
                for (const tableName of this.addedModulesTables) {
                  try {
                    let updatedDocs = [];
                    const docs = await this.cacheStorageService.getAllItems(tableName);
                    updatedDocs = docs.map(data => {
                      if (data.clientId === client.offlineId) {
                        data.clientId = client.id;
                      }
                      return data;
                    });
                    await this.cacheStorageService.clear(tableName);
                    await this.cacheStorageService.addItem(updatedDocs, tableName);
                  } catch (error) {
                    // Handle error if necessary
                    console.error(`Error updating table ${tableName}:`, error);
                  }
                }

                // Update 'clientsFamilyAdded' table
                let updatedDocs = [];
                const clientsFamilyAdded = await this.cacheStorageService.getAllItems('clientsFamilyAdded');
                updatedDocs = clientsFamilyAdded.map(opt => {
                  if (opt.clients && opt.clients.length > 0) {
                    opt.clients = opt.clients.map(item => item === client.offlineId ? client.id : item);
                  }
                  return opt;
                });
                await this.cacheStorageService.clear('clientsFamilyAdded');
                await this.cacheStorageService.addItem(updatedDocs, 'clientsFamilyAdded');

                await this.cacheStorageService.removeItem(element.id, 'clientsAdded');
                await this.cacheStorageService.addItem([client], 'clients');
                resolve2();
              } catch (error) {
                reject(error);
              }
            }, error => {
              this.downloadDataAsJson(this.addedTables);
              reject(error);
            });
          });
        }
        resolve1();
      } else {
        resolve1();
      }
    });
  }

  async saveProductsAdded(): Promise<void> {
    const productsAdded = await this.cacheStorageService.getAllItems('productAdded');
    this.allProductsAdded = productsAdded && productsAdded.length > 0 ? productsAdded.length : 0;
    if (this.allProductsAdded === 0) {
      this.productsProgressSyncValue = 100;
    }
    return new Promise<void>(async (resolveA, reject) => {
      if (productsAdded && productsAdded.length > 0) {
        // Use a for...of loop to ensure sequential execution
        for (const element of productsAdded) {
          const el = {
            ...element,
            id: null
          }
          el.familyId = null;
          await new Promise<void>((resolveB, reject) => {
            this.productService.addProduct(StorageUtils.getSelectedFolderId(), el).subscribe(async product => {
              product.offlineId = element.id;
              this.productsSyncValue++;
              if (this.productsProgressSyncValue !== 100) {
                this.productsProgressSyncValue = (this.productsSyncValue * 100) / this.allProductsAdded;
              }
              try {
                for (const tableName of this.addedModulesTables) {
                  try {
                    let updatedDocs = [];
                    const docs = await this.cacheStorageService.getAllItems(tableName);
                    updatedDocs = docs.map(data => {
                      if (data.productDTOS && data.productDTOS.length > 0) {
                        data.productDTOS.map(productLine => {
                          if (productLine.productId === product.offlineId) {
                            productLine.productId = product.id;
                          }
                        });
                      }
                      return data;
                    });
                    await this.cacheStorageService.clear(tableName);
                    await this.cacheStorageService.addItem(updatedDocs, tableName);
                  } catch (error) {
                    // Handle error if necessary
                    console.error(`Error updating table ${tableName}:`, error);
                  }
                }

                // Update 'productFamilyAdded' table
                let updatedDocs = [];
                const productFamilyAdded = await this.cacheStorageService.getAllItems('productFamilyAdded');
                updatedDocs = productFamilyAdded.map(opt => {
                  if (opt.products && opt.products.length > 0) {
                    opt.products.forEach(item => {
                      if (item.id === product.offlineId) {
                        item.id = product.id;
                      }
                    });
                  }
                  return opt;
                });
                await this.cacheStorageService.clear('productFamilyAdded');
                await this.cacheStorageService.addItem(updatedDocs, 'productFamilyAdded');
                await this.cacheStorageService.removeItem(element.id, 'productAdded');
                await this.cacheStorageService.addItem([product], 'product');
                resolveB();
              } catch (error) {
                reject(error);
              }
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              });
          });
        }
        resolveA();
      } else {
        resolveA();
      }
    });
  }

  saveFamilyClientsAdded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cacheStorageService.getAllItems('clientsFamilyAdded').then(res => {
        this.allClientFamiliesAdded = res && res.length > 0 ? res.length : 0;
        if (this.allClientFamiliesAdded === 0) {
          this.clientFamiliesProgressSyncValue = 100;
        }
        if (res && res.length > 0) {
          res.forEach(element => {
            const el = {
              ...element,
              id: null
            }
            this.clientFamilyService.createFamilyClient(StorageUtils.getSelectedFolderId(), el).subscribe(data => {
              this.clientFamiliesSyncValue++;
              if (this.clientFamiliesProgressSyncValue !== 100) {
                this.clientFamiliesProgressSyncValue = (this.clientFamiliesSyncValue * 100) / this.allClientFamiliesAdded;
              }
              this.cacheStorageService.removeItem(element.id, 'clientsFamilyAdded')
              this.cacheStorageService.addItem([data], 'clientsFamily');
              resolve();
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              })
          });
        } else {
          resolve();
        }
      });
    });
  }

  saveFamilyProductsAdded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cacheStorageService.getAllItems('productFamilyAdded').then(res => {
        this.allProductFamiliesAdded = res && res.length > 0 ? res.length : 0;
        if (this.allProductFamiliesAdded === 0) {
          this.productFamiliesProgressSyncValue = 100;
        }
        if (res && res.length > 0) {
          res.forEach(element => {
            const el = {
              ...element,
              id: null
            }
            this.productFamilyService.createProductFamily(StorageUtils.getSelectedFolderId(), el).subscribe(data => {
              this.productFamiliesSyncValue++;
              if (this.productFamiliesProgressSyncValue !== 100) {
                this.productFamiliesProgressSyncValue = (this.productFamiliesSyncValue * 100) / this.allProductFamiliesAdded;
              }
              this.cacheStorageService.removeItem(element.id, 'productFamilyAdded')
              this.cacheStorageService.addItem([data], 'productFamily');
              resolve();
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              })
          });
        } else {
          resolve();
        }
      });
    });
  }

  savePricesAdded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cacheStorageService.getAllItems('tarifAdded').then(res => {
        this.allPricesAdded = res && res.length > 0 ? res.length : 0;
        if (this.allPricesAdded === 0) {
          this.pricesProgressSyncValue = 100;
        }
        if (res && res.length > 0) {
          res.forEach(element => {
            const el = {
              ...element,
              id: null
            }
            this.priceService.createPrice(StorageUtils.getSelectedFolderId(), el).subscribe(data => {
              this.pricesSyncValue++;
              if (this.pricesProgressSyncValue !== 100) {
                this.pricesProgressSyncValue = (this.pricesSyncValue * 100) / this.allPricesAdded;
              }
              this.cacheStorageService.removeItem(element.id, 'tarifAdded')
              this.cacheStorageService.addItem([data], 'tarif');
              resolve();
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              });
          });
        } else {
          resolve();
        }
      });
    });
  }

  saveQuotesAdded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cacheStorageService.getAllItems('devisAdded').then(res => {
        this.allQuotesAdded = res && res.length > 0 ? res.length : 0;
        if (this.allQuotesAdded === 0) {
          this.quotesProgressSyncValue = 100;
        }
        if (res && res.length > 0) {
          res.forEach(element => {
            const el = {
              ...element,
              id: null
            }
            this.quoteService.createQuote(StorageUtils.getSelectedFolderId(), el).subscribe(data => {
              this.quotesSyncValue++;
              if (this.quotesProgressSyncValue !== 100) {
                this.quotesProgressSyncValue = (this.quotesSyncValue * 100) / this.allQuotesAdded;
              }
              this.cacheStorageService.removeItem(element.id, 'devisAdded')
              this.cacheStorageService.addItem([data], 'devis');
              resolve();
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              })
          });
        } else {
          resolve();
        }
      });
    });
  }

  saveOrderVouchersAdded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cacheStorageService.getAllItems('purchaseOrdersAdded').then(res => {
        if (res && res.length > 0) {
          res.forEach(element => {
            const el = {
              ...element,
              id: null
            }
            this.orderVouchersService.createOrderVoucher(StorageUtils.getSelectedFolderId(), el).subscribe(data => {
              this.cacheStorageService.removeItem(element.id, 'purchaseOrdersAdded')
              this.cacheStorageService.addItem([data], 'purchase-orders');
              resolve();
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              })
          });
        } else {
          resolve();
        }
      });
    });
  }

  saveDeliveryNotesAdded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cacheStorageService.getAllItems('blAdded').then(res => {
        this.allDeliveryNotesAdded = res && res.length > 0 ? res.length : 0;
        if (this.allDeliveryNotesAdded === 0) {
          this.deliveryNotesProgressSyncValue = 100;
        }
        if (res && res.length > 0) {
          res.forEach(element => {
            const el = {
              ...element,
              id: null
            }
            this.deliveryNoteService.createDilevryNote(StorageUtils.getSelectedFolderId(), el, false, false).subscribe(data => {
              this.deliveryNotesSyncValue++;
              if (this.deliveryNotesProgressSyncValue !== 100) {
                this.deliveryNotesProgressSyncValue = (this.deliveryNotesSyncValue * 100) / this.allDeliveryNotesAdded;
              }
              this.cacheStorageService.removeItem(element.id, 'blAdded')
              this.cacheStorageService.addItem([data], 'bl');
              resolve();
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              })
          });
        } else {
          resolve();
        }
      });
    });
  }

  saveFactureAdded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cacheStorageService.getAllItems('factureAdded').then(res => {
        this.allInvoicesAdded = res && res.length > 0 ? res.length : 0;
        if (this.allInvoicesAdded === 0) {
          this.invoicesProgressSyncValue = 100;
        }
        if (res && res.length > 0) {
          res.forEach(element => {
            const el = {
              ...element,
              id: null
            }
            this.invoiceService.createInvoice(StorageUtils.getSelectedFolderId(), el).subscribe(data => {
              this.invoicesSyncValue++;
              if (this.invoicesProgressSyncValue !== 100) {
                this.invoicesProgressSyncValue = (this.invoicesSyncValue * 100) / this.allInvoicesAdded;
              }
              this.cacheStorageService.removeItem(element.id, 'factureAdded')
              this.cacheStorageService.addItem([data], 'facture');
              resolve();
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              })
          });
        } else {
          resolve();
        }
      });
    });
  }

  saveCacheReceiptAdded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cacheStorageService.getAllItems('cashingsAdded').then(res => {
        this.allCashReseiptsAdded = res && res.length > 0 ? res.length : 0;
        if (this.allCashReseiptsAdded === 0) {
          this.cashReseiptProgressSyncValue = 100;
        }
        if (res && res.length > 0) {
          res.forEach(element => {
            const el = {
              ...element,
              id: null
            }
            this.cashingService.createCashing(StorageUtils.getSelectedFolderId(), el, el.interestType).subscribe(data => {
              this.cashReseiptsSyncValue++;
              if (this.cashReseiptProgressSyncValue !== 100) {
                this.cashReseiptProgressSyncValue = (this.cashReseiptsSyncValue * 100) / this.allCashReseiptsAdded;
              }
              this.cacheStorageService.removeItem(element.id, 'cashingsAdded')
              this.cacheStorageService.addItem([data], 'cashings');
              resolve();
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              })
          });
        } else {
          resolve();
        }
      });
    });
  }

  saveDisbursmentsAdded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cacheStorageService.getAllItems('disbursementsAdded').then(res => {
        this.allDisbursmentsAdded = res && res.length > 0 ? res.length : 0;
        if (this.allDisbursmentsAdded === 0) {
          this.disbursmentsProgressSyncValue = 100;
        }
        if (res && res.length > 0) {
          res.forEach(element => {
            const el = {
              ...element,
              id: null
            }
            this.disbursmentService.createDisbursment(StorageUtils.getSelectedFolderId(), el, el.interestType).subscribe(data => {
              this.disbursmentsSyncValue++;
              if (this.disbursmentsProgressSyncValue !== 100) {
                this.disbursmentsProgressSyncValue = (this.disbursmentsSyncValue * 100) / this.allDisbursmentsAdded;
              }
              this.cacheStorageService.removeItem(element.id, 'disbursementsAdded')
              this.cacheStorageService.addItem([data], 'disbursements')
            },
              error => {
                this.downloadDataAsJson(this.addedTables);
                reject(error);
              })
          });
          resolve();
        } else {
          resolve();
        }
      });
    });
  }

  async syncroniseData() {

    this.saveClientsAdded().then(() => {
      this.saveProductsAdded().then(() => {
        this.saveFamilyClientsAdded().then(() => {
          this.saveFamilyProductsAdded().then(() => {
            this.savePricesAdded().then(() => {
              this.saveQuotesAdded().then(() => {
                this.saveOrderVouchersAdded().then(() => {
                  this.saveDeliveryNotesAdded().then(() => {
                    this.saveFactureAdded().then(() => {
                      this.saveCacheReceiptAdded().then(() => {
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        this.saveDisbursmentsAdded().then(() => {

                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    });
  }

  notSaving() {
    return (this.clientsProgressValue === 100 &&
      this.productsProgressValue === 100 &&
      this.clientFamiliesProgressValue === 100 &&
      this.productFamiliesProgressValue === 100 &&
      this.pricesProgressValue === 100 &&
      this.invoicesProgressValue === 100 &&
      this.quotesProgressValue === 100 &&
      this.orderVouchersProgressValue === 100 &&
      this.deliveryNotesProgressValue === 100 &&
      this.cashReseiptProgressValue === 100 &&
      this.disbursmentsProgressValue === 100 &&
      this.fundingBoxsProgressValue === 100 &&
      this.providersProgressValue === 100) ||
      (this.clientsProgressValue === 0 &&
        this.productsProgressValue === 0 &&
        this.clientFamiliesProgressValue === 0 &&
        this.productFamiliesProgressValue === 0 &&
        this.pricesProgressValue === 0 &&
        this.invoicesProgressValue === 0 &&
        this.quotesProgressValue === 0 &&
        this.orderVouchersProgressValue === 0 &&
        this.deliveryNotesProgressValue === 0 &&
        this.cashReseiptProgressValue === 0 &&
        this.disbursmentsProgressValue === 0 &&
        this.fundingBoxsProgressValue === 0 &&
        this.providersProgressValue === 0) || !this.saving;
  }

  refreshData(storeName) {
    this.saving = true;
    this.cacheStorageService.clear(storeName);
    switch (storeName) {
      case 'clients':
        this.pageClients = 0;
        this.clients = [];
        this.saveClients(this.pageClients);
        this.clientsError = false;
        break;
      case 'product':
        this.pageProducts = 0;
        this.products = [];
        this.saveProducts(this.pageProducts);
        this.productsError = false;
        break;
      case 'clientsFamily':
        this.saveAllFamilyClients();
        this.clientFamiliesError = false;
        break;
      case 'productFamily':
        this.saveAllFamilyProducts();
        this.productFamiliesError = false;
        break;
      case 'tarif':
        this.saveAllPrices();
        this.pricesError = false;
        break;
      case 'devis':
        this.pageQuots = 0;
        this.quots = [];
        this.saveAllQuotes(this.pageQuots);
        this.quotesError = false;
        break;
      case 'orderVouchers':
        this.pageOrderVouchers = 0;
        this.orderVouchers = [];
        this.saveAllOrderVouchers(this.pageOrderVouchers);
        this.orderVoucherError = false;
        break;
      case 'bl':
        this.pageDelivery = 0;
        this.deliveryNotes = [];
        this.saveAllDeliveryNotes(this.pageDelivery);
        this.deliveryNotesError = false;
        break;
      case 'facture':
        this.pageInvoices = 0;
        this.invoices = [];
        this.saveFacture(this.pageInvoices);
        this.invoicesError = false;
        break;
      case 'cachings':
        this.pageCachings = 0;
        this.cachingsRec = [];
        this.saveAllCacheReceip(this.pageCachings);
        this.cashReseiptError = false;
        break;
      case 'disbursements':
        this.pageDisbursments = 0;
        this.disbursments = [];
        this.saveAllDisbursments(this.pageDisbursments);
        this.disbursmentsError = false;
        break;
      case 'cash-desks':
        this.pageFundingBox = 0;
        this.fundingBox = [];
        this.saveFundingBox(this.pageFundingBox);
        this.fundingBoxsError = false;
        break;
      case 'providers':
        this.pageProviders = 0;
        this.providers = [];
        this.saveAllProviders(this.pageProviders);
        this.providersError = false;
        break;
      case 'productClientFamiliesPriseList':
        this.pageProductClientFamiliesPriseList = 0;
        this.productClientFamiliesPriseList = [];
        this.saveProductClientFamiliesPriseList(this.pageProductClientFamiliesPriseList);
        this.productClientFamiliesPriseListError = false;
        break;

      default:
        break;
    }
    const data = [{
      id: 1,
      clients: { uploaded: this.clientsUploadedValue, total: this.configClients.totalItems, progressValue: this.clientsProgressValue, error: this.clientsError },
      products: { uploaded: this.productsUploadedValue, total: this.configProduct.totalItems, progressValue: this.productsProgressValue, error: this.productsError },
      clientFamilies: { uploaded: this.clientFamiliesUploadedValue, total: this.clientFamilyTotalItems, progressValue: this.clientFamiliesProgressValue, error: this.clientFamiliesError },
      productFamilies: { uploaded: this.productFamiliesUploadedValue, total: this.productFamilyTotalItems, progressValue: this.productFamiliesProgressValue, error: this.productFamiliesError },
      prices: { uploaded: this.pricesUploadedValue, total: this.pricesTotalItems, progressValue: this.pricesProgressValue, error: this.pricesError },
      invoices: { uploaded: this.invoicesUploadedValue, total: this.configFa.totalItems, progressValue: this.invoicesProgressValue, error: this.invoicesError },
      quotes: { uploaded: this.quotesUploadedValue, total: this.configQuot.totalItems, progressValue: this.quotesProgressValue, error: this.quotesError },
      deliveryNotes: { uploaded: this.deliveryNotesUploadedValue, total: this.configDelivery.totalItems, progressValue: this.deliveryNotesProgressValue, error: this.deliveryNotesError },
      cashReseipt: { uploaded: this.cashReseiptUploadedValue, total: this.configCacheReceip.totalItems, progressValue: this.cashReseiptProgressValue, error: this.cashReseiptError },
      disbursments: { uploaded: this.disbursmentsUploadedValue, total: this.configDisbursments.totalItems, progressValue: this.disbursmentsProgressValue, error: this.disbursmentsError },
      fundingBoxs: { uploaded: this.fundingBoxsUploadedValue, total: this.fundingBoxTotalItems, progressValue: this.fundingBoxsProgressValue, error: this.fundingBoxsError },
      providers: { uploaded: this.providersUploadedValue, total: this.providersTotalItems, progressValue: this.providersProgressValue, error: this.providersError },
      productClientFamiliesPriseList: { uploaded: this.productClientFamiliesPriseListUploadedValue, total: this.productClientFamiliesPriseListTotalItems, progressValue: this.productClientFamiliesPriseListProgressValue, error: this.productClientFamiliesPriseListError },
    }]
    this.cacheStorageService.addItem(data, 'uploadedDataInfos');
  }


  async downloadDataAsJson(storeNames: string[]) {
    try {
      const data = [];
      if (storeNames && storeNames.length > 0) {
        const fetchDataPromises = storeNames.map(async storeName => {
          const storedDataByTables = {};
          storedDataByTables[storeName] = await this.cacheStorageService.getAllItems(storeName);
          data.push(storedDataByTables);
        });

        // Wait for all fetchDataPromises to resolve
        await Promise.all(fetchDataPromises);

        const jsonData = JSON.stringify(data, null, 2);
        this.downloadFile(jsonData, `fastData.json`, 'application/json');
      }
    } catch (error) {
      console.error('Error downloading data: ', error);
    }
  }

  private downloadFile(data: string, fileName: string, mimeType: string) {
    const blob = new Blob([data], { type: mimeType });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();

    window.URL.revokeObjectURL(url);
  }

  saveUploadedDataInfos() {
    // this.cacheStorageService.clear('uploadedDataInfos').then(() => {
    const data = [{
      id: 1,
      clients: { uploaded: this.clients.length, total: this.configClients.totalItems, progressValue: this.clientsProgressValue, error: this.clientsError },
      products: { uploaded: this.products.length, total: this.configProduct.totalItems, progressValue: this.productsProgressValue, error: this.productsError },
      clientFamilies: { uploaded: this.clientFamilyTotalItems, total: this.clientFamilyTotalItems, progressValue: this.clientFamiliesProgressValue, error: this.clientFamiliesError },
      productFamilies: { uploaded: this.productFamilyTotalItems, total: this.productFamilyTotalItems, progressValue: this.productFamiliesProgressValue, error: this.productFamiliesError },
      prices: { uploaded: this.pricesTotalItems, total: this.pricesTotalItems, progressValue: this.pricesProgressValue, error: this.pricesError },
      invoices: { uploaded: this.invoices.length, total: this.configFa.totalItems, progressValue: this.invoicesProgressValue, error: this.invoicesError },
      quotes: { uploaded: this.quots.length, total: this.configQuot.totalItems, progressValue: this.quotesProgressValue, error: this.orderVoucherError },
      orderVouchers: { uploaded: this.orderVouchers.length, total: this.configOrderVouchers.totalItems, progressValue: this.orderVouchersProgressValue, error: this.quotesError },
      deliveryNotes: { uploaded: this.deliveryNotes.length, total: this.configDelivery.totalItems, progressValue: this.deliveryNotesProgressValue, error: this.deliveryNotesError },
      cashReseipt: { uploaded: this.cachingsRec.length, total: this.configCacheReceip.totalItems, progressValue: this.cashReseiptProgressValue, error: this.cashReseiptError },
      disbursments: { uploaded: this.disbursments.length, total: this.configDisbursments.totalItems, progressValue: this.disbursmentsProgressValue, error: this.disbursmentsError },
      fundingBoxs: { uploaded: this.fundingBox.length, total: this.fundingBoxTotalItems, progressValue: this.fundingBoxsProgressValue, error: this.fundingBoxsError },
      providers: { uploaded: this.providers.length, total: this.providersTotalItems, progressValue: this.providersProgressValue, error: this.providersError },
      productClientFamiliesPriseList: { uploaded: this.productClientFamiliesPriseList.length, total: this.productClientFamiliesPriseListTotalItems, progressValue: this.productClientFamiliesPriseListProgressValue, error: this.productClientFamiliesPriseListError },
    }]
    this.cacheStorageService.addItem(data, 'uploadedDataInfos');
    // });
  }

  setUploadedData() {
    this.cacheStorageService.getAllItems('uploadedDataInfos').then((data) => {
      this.uploadedDataInfos = data[0];
      if (this.uploadedDataInfos) {
        // Progress values
        this.clientsProgressValue = data[0].clients.progressValue;
        this.productsProgressValue = data[0].products.progressValue;
        this.clientFamiliesProgressValue = data[0].clientFamilies.progressValue;
        this.productFamiliesProgressValue = data[0].productFamilies.progressValue;
        this.pricesProgressValue = data[0].prices.progressValue;
        this.invoicesProgressValue = data[0].invoices.progressValue;
        this.quotesProgressValue = data[0].quotes.progressValue;
        this.deliveryNotesProgressValue = data[0].deliveryNotes.progressValue;
        this.cashReseiptProgressValue = data[0].cashReseipt.progressValue;
        this.disbursmentsProgressValue = data[0].disbursments.progressValue;
        this.fundingBoxsProgressValue = data[0].fundingBoxs.progressValue;
        this.providersProgressValue = data[0].providers.progressValue;
        this.productClientFamiliesPriseListProgressValue = data[0].productClientFamiliesPriseList.progressValue;

        // Progress uploaded values
        this.clientsUploadedValue = data[0].clients.uploaded;
        this.productsUploadedValue = data[0].products.uploaded;
        this.clientFamiliesUploadedValue = data[0].clientFamilies.uploaded;
        this.productFamiliesUploadedValue = data[0].productFamilies.uploaded;
        this.pricesUploadedValue = data[0].prices.uploaded;
        this.invoicesUploadedValue = data[0].invoices.uploaded;
        this.quotesUploadedValue = data[0].quotes.uploaded;
        this.deliveryNotesUploadedValue = data[0].deliveryNotes.uploaded;
        this.cashReseiptUploadedValue = data[0].cashReseipt.uploaded;
        this.disbursmentsUploadedValue = data[0].disbursments.uploaded;
        this.fundingBoxsUploadedValue = data[0].fundingBoxs.uploaded;
        this.providersUploadedValue = data[0].providers.uploaded;
        this.productClientFamiliesPriseListUploadedValue = data[0].productClientFamiliesPriseList.uploaded;

        // Total values
        this.configClients.totalItems = data[0].clients.total;
        this.configProduct.totalItems = data[0].products.total;
        this.clientFamilyTotalItems = data[0].clientFamilies.total;
        this.productFamilyTotalItems = data[0].productFamilies.total;
        this.pricesTotalItems = data[0].prices.total;
        this.configFa.totalItems = data[0].invoices.total;
        this.configQuot.totalItems = data[0].quotes.total;
        this.configDelivery.totalItems = data[0].deliveryNotes.total;
        this.configCacheReceip.totalItems = data[0].cashReseipt.total;
        this.configDisbursments.totalItems = data[0].disbursments.total;
        this.fundingBoxTotalItems = data[0].fundingBoxs.total;
        this.providersTotalItems = data[0].providers.total;
        this.productClientFamiliesPriseListTotalItems = data[0].productClientFamiliesPriseList.total;

        // Upload errors module
        this.clientsError = data[0].clients.error;
        this.productsError = data[0].products.error;
        this.clientFamiliesError = data[0].clientFamilies.error;
        this.productFamiliesError = data[0].productFamilies.error;
        this.pricesError = data[0].prices.error;
        this.invoicesError = data[0].invoices.error;
        this.quotesError = data[0].quotes.error;
        this.deliveryNotesError = data[0].deliveryNotes.error;
        this.cashReseiptError = data[0].cashReseipt.error;
        this.disbursmentsError = data[0].disbursments.error;
        this.fundingBoxsError = data[0].fundingBoxs.error;
        this.providersError = data[0].providers.error;
        this.productClientFamiliesPriseListError = data[0].productClientFamiliesPriseList.error;

      }
    });
  }

  async clearUploadedData(): Promise<void> {
    return new Promise<void>(resolve => {
      // this.unploadedTables.forEach(async (table) => {
      //   this.cacheStorageService.clear(table);
      // });
      for (const table of this.unploadedTables) {
        this.cacheStorageService.clear(table);
      }
      resolve();
    })
  }

}
