<div>
  <mat-accordion class="example-headers-align" (click)="$event.preventDefault(); $event.stopPropagation()">
    <mat-expansion-panel (opened)="setUploadedData()" hideToggle>
      <mat-expansion-panel-header class="expansion-panel-header">
        <mat-panel-title>
          <div class="mat-pannel-title-container">
            <span>{{downloadData | translate}}</span>
            <button mat-mini-fab class="custom-mini-fab"
              (click)="$event.preventDefault(); $event.stopPropagation(); startSaving()">
              <mat-icon class="btn-icon">cloud_download</mat-icon>
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container" (click)="$event.preventDefault(); $event.stopPropagation()">
        <button *ngFor="let act of actions" (click)="act.onClick(act)" class="mat-menu-item">
          <span>{{ act.label | translate}}</span>
        </button>
        <div *ngIf="invoicesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.invoice' | translate}}
              <mat-icon class="refresh-btn" *ngIf="invoicesError"
                (click)="refreshData('facture')">refresh</mat-icon></span>
            <span class="progress" *ngIf="configFa?.totalItems">{{invoicesUploadedValue + '/' +
              configFa?.totalItems}}</span>
          </div>
          <div>
            <mat-progress-bar mode="determinate" [value]="invoicesProgressValue"></mat-progress-bar>
          </div>
        </div>
        <div *ngIf="productsPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.products' | translate}}</span>
            <mat-icon class="refresh-btn" *ngIf="productsError" (click)="refreshData('product')">refresh</mat-icon>
            <span class="progress" *ngIf="configProduct?.totalItems">{{productsUploadedValue + '/' +
              configProduct?.totalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="productsProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="clientsPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.clients' | translate}}
              <mat-icon class="refresh-btn" *ngIf="clientsError"
                (click)="refreshData('clients')">refresh</mat-icon></span>
            <span class="progress" *ngIf="configClients?.totalItems">{{clientsUploadedValue + '/' +
              configClients?.totalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="clientsProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="productFamiliesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.productsFamily' | translate}}</span>
            <mat-icon class="refresh-btn" *ngIf="productFamiliesError"
              (click)="refreshData('productFamily')">refresh</mat-icon>
            <span class="progress" *ngIf="productFamilyTotalItems">{{productFamiliesUploadedValue + '/' +
              productFamilyTotalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="productFamiliesProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="clientFamiliesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.clientFamily' | translate}}
              <mat-icon class="refresh-btn" *ngIf="clientFamiliesError"
                (click)="refreshData('clientsFamily')">refresh</mat-icon></span>
            <span class="progress" *ngIf="clientFamilyTotalItems">{{clientFamiliesUploadedValue + '/' +
              clientFamilyTotalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="clientFamiliesProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="pricesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.prices' | translate}}</span>
            <mat-icon class="refresh-btn" *ngIf="pricesError" (click)="refreshData('tarif')">refresh</mat-icon>
            <span class="progress" *ngIf="pricesTotalItems">{{pricesUploadedValue + '/' + pricesTotalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="pricesProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="quotesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.quotes' | translate}}
              <mat-icon class="refresh-btn" *ngIf="quotesError" (click)="refreshData('devis')">refresh</mat-icon></span>
            <span class="progress" *ngIf="configQuot?.totalItems">{{quotesUploadedValue + '/' +
              configQuot?.totalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="quotesProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="deliveryNotesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.deliverynotes' | translate}}
              <mat-icon class="refresh-btn" *ngIf="deliveryNotesError"
                (click)="refreshData('bl')">refresh</mat-icon></span>
            <span class="progress" *ngIf="configDelivery?.totalItems">{{deliveryNotesUploadedValue + '/' +
              configDelivery?.totalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="deliveryNotesProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="orderVouchersPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.orderVouchers' | translate}}
              <mat-icon class="refresh-btn" *ngIf="orderVoucherError"
                (click)="refreshData('orderVouchers')">refresh</mat-icon></span>
            <span class="progress" *ngIf="configOrderVouchers?.totalItems">{{orderVoucherUploadedValue + '/' +
              configOrderVouchers?.totalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="quotesProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="cashReseiptPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.cashReseipt' | translate}}
              <mat-icon class="refresh-btn" *ngIf="cashReseiptError"
                (click)="refreshData('cachings')">refresh</mat-icon></span>
            <span class="progress" *ngIf="configCacheReceip?.totalItems">{{cashReseiptUploadedValue + '/' +
              configCacheReceip?.totalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="cashReseiptProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="disbursmentsPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.disbursments' | translate}}</span>
            <mat-icon class="refresh-btn" *ngIf="disbursmentsError"
              (click)="refreshData('disbursements')">refresh</mat-icon>
            <span class="progress" *ngIf="configDisbursments?.totalItems">{{disbursmentsUploadedValue + '/' +
              configDisbursments.totalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="disbursmentsProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="fundingBoxsPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.fundingBox' | translate}}</span>
            <mat-icon class="refresh-btn" *ngIf="fundingBoxsError"
              (click)="refreshData('cash-desks')">refresh</mat-icon>
            <span class="progress" *ngIf="fundingBoxTotalItems">{{fundingBoxsUploadedValue + '/' +
              fundingBoxTotalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="fundingBoxsProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="providersPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.providers' | translate}}
              <mat-icon class="refresh-btn" *ngIf="providersError"
                (click)="refreshData('providers')">refresh</mat-icon></span>
            <span class="progress" *ngIf="providersTotalItems">{{providersUploadedValue + '/' +
              providersTotalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="providersProgressValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="pricesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.productClientFamiliesPriseList' | translate}}
              <mat-icon class="refresh-btn" *ngIf="productClientFamiliesPriseListError"
                (click)="refreshData('productClientFamiliesPriseList')">refresh</mat-icon></span>
            <span class="progress"
              *ngIf="productClientFamiliesPriseListTotalItems">{{productClientFamiliesPriseListUploadedValue + '/' +
              productClientFamiliesPriseListTotalItems}}</span>
          </div>
          <div><mat-progress-bar mode="determinate"
              [value]="productClientFamiliesPriseListProgressValue"></mat-progress-bar></div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header class="expansion-panel-header">
        <mat-panel-title>
          <div class="mat-pannel-title-container">
            <span>{{uploadData | translate}}</span>
            <button mat-mini-fab class="custom-mini-fab"
              (click)="$event.preventDefault(); $event.stopPropagation(); syncroniseData()">
              <mat-icon class="btn-icon">cloud_upload</mat-icon>
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container" (click)="$event.preventDefault(); $event.stopPropagation()">
        <div *ngIf="invoicesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.invoice' | translate}}</span>
            <span class="progress" *ngIf="allInvoicesAdded">{{invoicesSyncValue + '/' +
              allInvoicesAdded}}</span>
          </div>
          <div>
            <mat-progress-bar mode="determinate" [value]="invoicesProgressSyncValue"></mat-progress-bar>
          </div>
        </div>
        <div *ngIf="productsPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.products' | translate}}</span>
            <span class="progress" *ngIf="allProductsAdded">{{productsSyncValue + '/' +
              allProductsAdded}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="productsProgressSyncValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="clientsPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.clients' | translate}}</span>
            <span class="progress" *ngIf="allClientsAdded">{{clientsSyncValue + '/' +
              allClientsAdded}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="clientsProgressSyncValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="productFamiliesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.productsFamily' | translate}}</span>
            <span class="progress" *ngIf="allProductFamiliesAdded">{{productFamiliesSyncValue + '/' +
              allProductFamiliesAdded}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="productFamiliesProgressSyncValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="clientFamiliesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.clientFamily' | translate}}</span>
            <span class="progress" *ngIf="allClientFamiliesAdded">{{clientFamiliesSyncValue + '/' +
              allClientFamiliesAdded}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="clientFamiliesProgressSyncValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="pricesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.prices' | translate}}</span>
            <span class="progress" *ngIf="allPricesAdded">{{pricesSyncValue + '/' + allPricesAdded}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="pricesProgressSyncValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="quotesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.quotes' | translate}}</span>
            <span class="progress" *ngIf="allQuotesAdded">{{quotesProgressValue + '/' +
              allQuotesAdded}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="quotesProgressSyncValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="deliveryNotesPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.deliverynotes' | translate}}</span>
            <span class="progress" *ngIf="allDeliveryNotesAdded">{{deliveryNotesSyncValue + '/' +
              allDeliveryNotesAdded}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="deliveryNotesProgressSyncValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="cashReseiptPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.cashReseipt' | translate}}</span>
            <span class="progress" *ngIf="allCashReseiptsAdded">{{cashReseiptsSyncValue + '/' +
              allCashReseiptsAdded}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="cashReseiptProgressSyncValue"></mat-progress-bar></div>
        </div>
        <div *ngIf="disbursmentsPrivileges?.canWrite">
          <div class="progress-title">
            <span>{{'offlineContext.progressItems.disbursments' | translate}}</span>
            <span class="progress" *ngIf="allDisbursmentsAdded">{{disbursmentsSyncValue + '/' +
              allDisbursmentsAdded}}</span>
          </div>
          <div><mat-progress-bar mode="determinate" [value]="disbursmentsProgressSyncValue"></mat-progress-bar></div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>