export const locale = {
    lang: 'fr',
    data: {
        offlineContext: {
            progressItems: {
                invoice: 'Factures',
                products: 'Produits',
                clients: 'clients',
                productsFamily: 'Famille produits',
                clientFamily: 'Famille clients',
                prices: 'Grille des tarifs',
                quotes: 'Devis',
                deliverynotes: 'bons de livraison',
                cashReseipt: 'Encaissements',
                disbursments: 'Décaissements',
                fundingBox: 'Caisses',
                providers: 'Fournisseurs',
                productClientFamiliesPriseList: 'Tarifs par familles',
                orderVouchers: 'Bon de commande',

            },
            tooltips: {
                download: 'Télécharger les données',
                upload: 'Synchroniser les données',
                infos: 'Informations sur les données téléchargées',
            },
            messages: {
                warningMessage: 'Veuillez sauvegarder vos données pour avoir accès au mode Offline',
                refreshWarningMessage: 'Vous êtes en mode hors ligne, veuillez ne pas actualiser la page.',
            }
        }
    },
};
