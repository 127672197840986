export const locale = {
    lang: 'fr',
    data: {
        folderSearch: {
            placeHolder: 'Sélectionnez un dossier',
            createFolder: 'Créer un dossier',
            readMode: 'Mode lecture'
        },
        navigation: {
            myProfil: 'Mon profil',
            administration: 'Administration',
            folderManagement: 'Gestion des dossiers',
            logout: 'Se déconnecter',
            changeFolder: 'Changement de dossier...',
            language: 'Langue',
        },
        accountSearch: {
            placeHolder: 'Séléctionnez un compte client'
        },
        notifications: {
            empty: 'Aucune nouvelle notification',
            markAllAsRead: 'Tout marquer comme lu',
            markAsRead: 'Marquer comme lu',
            moreDetails: 'Plus de détails',
            nrNotification: 'Nouvelle notification',
            emptyData: 'Aucune notification',
            pending: 'Chargement ...',
            rappelDes: 'Vous avez un rappel sur ',
            types: {
                0: 'N’oubliez pas de régler la CNAS avant la fin du mois',
                1: 'Nouvelle demande de congé',
                2: 'Nouvelle demande d’absence',
                3: 'N’oubliez pas de régler l’IRG avant le 20 du mois',
            },
            errors: {
                emptyAbsence: 'La demande d\'absence n\'est plus disponible',
                emptyLeave: 'La demande du congé n\'est plus disponible',
            },
            docTypes: {
                QUOTE: 'Devis',
                DELIVERY_NOTE: 'BL',
                INVOICE: 'Facture',
                RETURN_VOUCHER: 'Retour',
                CREDIT_NOTE: 'Avoir',
                RECEIPT_VOUCHER: 'Bon de récéption',
                SUPPLIER_PURCHASE_ORDER: 'BDC Fournisseur',
                SUPPLIER_RETURN_ORDER: 'Retour fournisseur',
                ENTRY_VOUCHER: 'Bon d\'entrée',
                EXIT_VOUCHER: 'Bon de sortie',
                TRANSFER_VOUCHER: 'Bon de transfert',
                SUPPLIER_INVOICE: 'Facture ournisseur',
                DISBURSEMENT: 'Décaissement',
                CASH_RECEIPT: 'Encaissement',
                REPPORT_CASH: '',
                PURCHASE_ORDER: 'Bon de commande',
            }
        }
    },
};
