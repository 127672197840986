export const locale = {
    lang: 'dz',
    data: {
        folderSearch: {
            placeHolder: 'اختر مجلد',
            createFolder: 'إنشاء مجلد',
        },
        navigation: {
            myProfil: 'ملفي الشخصي',
            administration: 'الإدارة',
            folderManagement: 'تسيير المجلدات',
            logout: 'تسجيل الخروج',
            changeFolder: 'تغيير الملف',
            language: 'اللغة',

        },
        accountSearch: {
            placeHolder: 'حدد حساب الزبون'
        },
        notifications: {
            empty: 'Aucune nouvelle notification',
            markAllAsRead: 'Tout marquer comme lu',
            markAsRead: 'Marquer comme lu',
            moreDetails: 'Plus de détails',
            nrNotification: 'Nouvelle notification',
            types: {
                0: 'N’oubliez pas de régler la CNAS avant la fin du mois',
                1: 'Nouvelle demande de congé',
                2: 'Nouvelle demande d’absence',
                3: 'N’oubliez pas de régler l’IRG avant le 20 du mois',
            },
            errors: {
                emptyAbsence: 'La demande d\'absence n\'est plus disponible (AR)',
                emptyLeave: 'La demande du congé n\'est plus disponible (AR)',
            }
        }
    },
};
