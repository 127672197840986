<mat-toolbar class="p-0" style="height: 55px;">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="space-between center">
        <div fxHide.gt-md>
            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>

        <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>
        <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

        <div fxFlex=" 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <div fxHide.gt-md>
                <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar && !inPlugin"
                    (click)="toggleSidebarOpen('navbar')">
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>
            </div>
            <div class="breadcrumb-container" *ngIf="displayBC">
                <!--  <app-breadcrumb></app-breadcrumb> -->
            </div>
            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg" alt="" />
                </div>
            </div>
        </div>
        <div class="center-content" [ngStyle]="{justifyContent: minimalWidthDisplay ? 'flex-start' : 'center'}">
            <app-context-selector [context]="context" [minimalWidthDisplay]='minimalWidthDisplay'
                (changeRoleEmitter)="changeRoleEmitter($event)"
                (changeIsAdminClientEmitter)="changeIsAdminClientEmitter($event)">
            </app-context-selector>
        </div>

        <div class="btns-container">

            <div>
                <div class="example-button-container">
                    <button mat-mini-fab color="accent" class="custom-mini-fab" [matMenuTriggerFor]="offline_menu"
                        aria-label="Example icon button with a bookmark icon">
                        <mat-icon>language</mat-icon>
                    </button>
                </div>
                <mat-menu #offline_menu="matMenu" (click)="$event.preventDefault(); $event.stopPropagation()">
                    <div>
                        <app-context-offline></app-context-offline>
                    </div>
                </mat-menu>
            </div>


            <div>
                <div class="example-button-container">
                    <button mat-mini-fab color="accent" class="custom-mini-fab" [matMenuTriggerFor]="menu_notif"
                        [matBadge]="notViewedNotificationsCount"
                        [matBadgeHidden]="notifications.length === 0 || notViewedNotificationsCount === 0" color="red"
                        (click)="fetchNotifications()">
                        <mat-icon>notifications_none</mat-icon>
                    </button>
                </div>
                <mat-menu #menu_notif="matMenu" (click)="$event.preventDefault(); $event.stopPropagation()">
                    <div class="notification-list" infiniteScroll [infiniteScrollDistance]="2"
                        [infiniteScrollThrottle]="50" (scrolled)="onScroll($event)" [scrollWindow]="false">
                        <div class="notifications-container" *ngFor="let notification of notifications"
                            (click)="redirectTo(notification)">
                            <div class="notification-container" [ngClass]="{'not-viewed-notif': !notification.viewed}">
                                <div class="notification">
                                    <div class="notification-title-container">
                                        <span class="notification-title">{{'notifications.docTypes.' +
                                            notification.documentType | translate}}</span>
                                        <div class="notification-title-date-container">
                                            <span
                                                class="notification-title-date-description">{{notification.date}}</span>
                                            <div *ngIf="!notification.viewed" class="not-viewed-notif-red-dot"></div>
                                        </div>
                                    </div>
                                    <div class="notification-description-description">
                                        <span class="notification-description">{{'notifications.rappelDes'|
                                            translate}}</span>
                                        <span class="notification-description-doc-name">{{notification.documentName
                                            }}</span>
                                    </div>
                                    <span class="notification-body">{{notification.note}}</span>
                                </div>
                            </div>
                            <mat-divider *ngIf="notification !== notifications[notifications.length - 1]"></mat-divider>
                        </div>
                        <div class="null-notifications" *ngIf="notifications.length === 0 && !pendingNotifs">
                            <span>{{'notifications.emptyData' | translate}}</span>
                        </div>
                        <div class="pending-notifications" *ngIf="notifications.length === 0 && pendingNotifs">
                            <span>{{'notifications.pending' | translate}}</span>
                        </div>
                    </div>
                    <div class="loader-container">
                        <div class="loader" *ngIf="notifications.length !== 0 && pendingNotifs"></div>
                    </div>
                </mat-menu>
            </div>


            <div>
                <div class="example-button-container">
                    <button mat-mini-fab color="accent" class="custom-mini-fab" [matMenuTriggerFor]="menu"
                        aria-label="Example icon button with a bookmark icon">
                        <mat-icon>person</mat-icon>
                    </button>
                </div>
                <mat-menu #menu="matMenu" (click)="$event.preventDefault(); $event.stopPropagation()">
                    <div class="btn-in-menu avatar-container" (click)="goTo('profile')">
                        <mat-icon class="btn-header-icon account-icon"
                            *ngIf="this.userData && !this.userData.attachmentId">person</mat-icon>
                        <img class="avatar avatar-style" [src]="imgSrc"
                            *ngIf="this.userData && this.userData.attachmentId"
                            [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset' , 'padding' : this.userData && this.userData.attachmentId ? 'unset !important' : '6px', 'background-color': this.userData && this.userData.attachmentId ? 'white !important' : '#b3b3b3'}"
                            alt="" />
                        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="userData">
                            <span class="btn-menu-text" style="text-transform: uppercase;">{{
                                userData.lastName.length < 20 ? userData.lastName : (userData.lastName | slice:0:20)
                                    + '...' }}</span>
                                    <span class="btn-menu-text" style="text-transform: capitalize;">
                                        {{userData.lastName.length < 20 ? userData.lastName.length +
                                            userData.firstName.length < 20 ? userData.firstName : (userData.firstName |
                                            slice:0:(20 - userData.lastName.length)) + '...' : '' }}</span>
                        </div>
                        <span class="btn-menu-text" *ngIf="!userData">{{'Mon profile'}}</span>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="btn-in-menu"
                        (click)="$event.preventDefault(); $event.stopPropagation(); showHideLangOpts()">
                        <mat-icon class="btn-header-icon">g_translate</mat-icon>
                        <span class="btn-menu-text">{{'Langue'}}</span>
                    </div>
                    <div [ngClass]="selectedLanguage.id === 'fr' ? 'languages-container-fr' : 'languages-container-dz'"
                        [@langAnimation]="showLanguages ? 'open' : 'closed'">
                        <div class="btn-in-menu" *ngFor="let lang of languages" (click)="setLanguage(lang)"
                            [ngClass]="lang === selectedLanguage ? 'selected-lang' : null">
                            <mat-icon class="btn-header-icon">g_translate</mat-icon>
                            <span class="btn-menu-text">{{ lang.title }}</span>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="btn-in-menu" (click)="changeContext('administration')"
                        *ngIf="isAdminClient && !isAdminContext"
                        [matTooltip]="role && role === 'ROLE_USER_CLIENT' ? ('noAccess'|translate):''">
                        <mat-icon class="btn-header-icon">settings</mat-icon>
                        <span class="btn-menu-text">{{ 'navigation.administration' | translate }}</span>
                    </div>
                    <div class="btn-in-menu" *ngIf="isAdminContext" (click)="changeContext('folderManagement')"
                        [matTooltip]="role && role === 'ROLE_USER_CLIENT' ? ('noAccess'|translate):''">
                        <mat-icon class="btn-header-icon">settings</mat-icon>
                        <span class="btn-menu-text">{{ 'navigation.folderManagement' | translate }}</span>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="btn-in-menu" (click)="goTo('logout')">
                        <img class="logo-icon logout-icon" src="assets/icons/material-icons/new/logout.svg" alt="">
                        <span class="btn-menu-text">{{'Déconnexion'}}</span>
                    </div>
                </mat-menu>
            </div>
        </div>
        <!-- <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button toolbar-btns">
                <div fxLayout="row" fxLayoutAlign="center center" *ngIf="userData">
                    <img class="avatar avatar-style mr-0 mr-sm-8" [src]="imgSrc"
                        [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset' , 'padding' : this.userData && this.userData.attachmentId ? 'unset !important' : '6px', 'background-color': this.userData && this.userData.attachmentId ? 'white !important' : '#b3b3b3'}"
                        alt="" />
                    <span class="username mr-4" fxHide fxShow.gt-sm style="text-transform: uppercase;">{{
                        userData.lastName.length < 20 ? userData.lastName : (userData.lastName | slice:0:20) + '...'
                            }}</span>
                            <span class="username mr-12" fxHide fxShow.gt-sm
                                style="text-transform: capitalize;">{{userData.lastName.length < 20 ?
                                    userData.lastName.length + userData.firstName.length < 20 ? userData.firstName :
                                    (userData.firstName | slice:0:(20 - userData.lastName.length)) + '...' : ''
                                    }}</span>
                                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <div [ngClass]="fuseConfig.layout.toolbar.background">
                    <button mat-menu-item (click)="goTo('profile')"
                        [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                        <mat-icon
                            [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                            account_circle</mat-icon>
                        <span>{{'navigation.myProfil' | translate }}</span>
                    </button>

                    <div [matTooltip]="role && role === 'ROLE_USER_CLIENT' ? ('noAccess'|translate):''">
                        <button [disabled]="role && role === 'ROLE_USER_CLIENT'"
                            *ngIf="isAdminClient && !isAdminContext" mat-menu-item
                            (click)="changeContext('administration')"
                            [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                            <mat-icon class="avatar-style"
                                [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                                {{ 'navigation.folderManagement' | translate }}</mat-icon>
                            <span> {{ 'navigation.administration' | translate }}</span>
                        </button>
                    </div>

                    <button *ngIf="isAdminContext" mat-menu-item (click)="changeContext('folderManagement')"
                        [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                        <mat-icon
                            [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                            collections_bookmark</mat-icon>
                        <span> {{ 'navigation.folderManagement' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="goTo('logout')"
                        [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                        <mat-icon
                            [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                            exit_to_app</mat-icon>
                        <span>{{ 'navigation.logout' | translate }}</span>
                    </button>
                </div>
            </mat-menu>


            <div class="toolbar-separator"></div>

            <button mat-button class="language-button toolbar-btns" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="iso text-uppercase">{{
                        selectedLanguage.id
                        }}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false" class="mat-menu">
                <div [ngClass]="fuseConfig.layout.toolbar.background">
                    <div *ngFor="let lang of languages">
                        <button mat-menu-item (click)="setLanguage(lang)"
                            [ngStyle]="{'background-color': lang === selectedLanguage? 'rgba(0, 0, 0, 0.04)' : null}">
                            <span fxLayout="row" fxLayoutAlign="center center">
                                <span class="iso"
                                    [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">{{
                                    lang.title }}</span>
                            </span>
                        </button>
                    </div>
                </div>

            </mat-menu>

        </div> -->
    </div>
</mat-toolbar>