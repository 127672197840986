@import "/src/@fuse/scss/fuse.scss";
app-toolbar {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  z-index: 4;
  background-color: white;
  border-bottom: 1px solid #1b243a2e;

  &.below {
    z-index: 2;
  }

  .btns-container {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .custom-mini-fab {
    box-shadow: none;
    background-color: #272d3f;
    color: whitesmoke;
  }

  .custom-mini-fab:hover {
    background-color: whitesmoke;
    color: #272d3f;
  }

  .mat-icon {
    margin-top: -1px;
    max-width: 30px !important;
    width: 30px !important;
    max-height: 30px !important;
    height: 30px !important;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-toolbar {
    position: relative;
    background: inherit !important;
    color: inherit !important;
  }

  .logo {
    display: flex;
    align-items: center;

    .logo-icon {
      width: 38px;
    }
  }

  .avatar-style {
    // background-color: #b3b3b3;
    // padding: 6px;
    height: 38px !important;
    width: 38px !important;
    min-width: 38px !important;
  }

  .user-button,
  fuse-search-bar,
  .language-button,
  .alert-button,
  .chat-panel-toggle-button,
  .quick-panel-toggle-button {
    min-width: 64px !important;
    height: 55px !important;

    @include media-breakpoint("xs") {
      height: 56px !important;
    }
  }

  .navbar-toggle-button {
    min-width: 56px !important;
    height: 56px !important;
    border-radius: 0 !important;

    & .mat-mdc-button-persistent-ripple {
      border-radius: 0 !important;
    }
  }

  .nIcon {
    color: rgba(0, 0, 0, 0.54);
    font-size: 26px;
    height: 26px !important;
    width: 26px !important;
    vertical-align: baseline;
  }

  .nDot {
    background-color: #ed5564;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-top: -10px;
    margin-left: -14px;
    border: 1px whitesmoke solid;
    animation: pulse-red 2s infinite;
  }

  @keyframes pulse-red {
    0% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 25px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }

  .toolbar-separator {
    height: 55px !important;
    width: 1px;

    @include media-breakpoint("xs") {
      height: 56px;
    }
  }

  .folder-form {
    padding-top: 20px;

    .classInput {
      font-size: 13px;
    }

    .selectedNoImage {
      display: inline-block;
      height: 30px;
      width: 30px;
      background-color: #039be5 !important;
      border-radius: 15px;
      margin-right: 7px;
      color: white;
      font-weight: 900;
    }

    .selectedImage {
      display: inline-block;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      margin-right: 7px;
      color: white;
      font-weight: 900;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
::ng-deep .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
  height: 54px !important;
}

.mat-menu2 {
  max-width: unset !important;

  .mat-menu-content:not(:empty) {
    width: 420px !important;
    background-color: whitesmoke;
    padding: 0 !important;
  }
}

.notifications-menu {
  width: 100%;
  min-height: 80px;
  max-height: 410px;
  overflow: auto;
}

.single-notification-nr {
  width: 100%;
  background-color: white;
  padding: 0 15px;
  border-bottom: 1px solid #1e212917;
  text-align: justify;
  min-height: 100px;

  .notif-content {
    width: 100%;
    font-size: 14px;

    .preIcon {
      margin-top: 10px;
    }

    .notText {
      margin-left: 5px;
      padding: 9px 4px;
      font-weight: 600;
      width: 100%;
      line-height: 1.2;

      .date-style {
        width: 100%;
        font-size: 13px;
        padding: 5px 0;
      }

      .details-style:hover {
        color: #039be5 !important;
        cursor: pointer;
        background-color: transparent;
      }

      .content-not-style {
        padding: 5px 0;
      }

      mat-icon {
        font-size: 16px;
        height: 16px !important;
        min-height: unset !important;
        width: 16px !important;
        min-width: unset !important;
        margin-right: 2px;
      }
    }
  }

  .notif-actions {
    padding-left: 10px;
    padding-right: 5px;

    button {
      height: 36px !important;
      width: 36px !important;
      min-width: unset !important;
      padding: unset !important;
      border-radius: 51%;
      background-color: #1e2129de;
      color: white;

      mat-icon {
        font-size: 20px !important;
        text-align: center;
      }
    }

    button:hover {
      background-color: #2d323ec4;
    }
  }
}

.single-notification-nr:hover {
  background-color: #2b2b2b05 !important;
}

.readed {
  background-color: #2b2b2b05 !important;
  cursor: unset !important;

  button {
    background-color: #1e212930 !important;
    color: #35353585 !important;
  }

  .notText {
    font-weight: 300 !important;
  }
}

.readed:hover {
  background-color: #2b2b2b05 !important;
}

.notifications-action-bar {
  height: 25px;
  width: 100%;
  background: white !important;
  font-size: 13px;

  mat-icon {
    font-size: 15px;
    text-align: center;
    padding-top: 4px;
  }
}

.notifications-action-bar:disabled {
  color: #838484 !important;
}

.no-new-notifications {
  min-height: 110px;
  width: 100%;

  .emptyIcon {
    font-size: 20px;
    height: 35px;
    width: 35px;
    padding-top: 7px;
    text-align: center;
    background-color: #1e212930;
    color: white;
    border-radius: 33px;
  }

  .emptyText {
    padding-top: 6px;
    color: #2d323e9e;
  }
}

.disabled-notif {
  pointer-events: none;
}

// .mat-menu-panel {
//     max-width: 370px !important;
// }

@media print {
  app-toolbar {
    display: none !important;
  }
}

.toolbar-btns {
  height: 55px;
}

.center-content {
  width: 100%;
  display: flex;
  align-items: center;
}

.mat-menu-content:not(:empty) {
  padding: 0px !important;
}

.btn-in-menu {
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 15px;
  cursor: pointer;

  .btn-header-icon {
    margin-right: 5px;
  }
}

.avatar-container {
  width: 240px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  padding: 15px;

  .btn-header-icon {
    margin-right: 5px;
  }
}

.account-icon {
  color: white;
  background-color: #272d3e;
  border-radius: 50%;
}

.logout-icon {
  margin-right: 5px;
}

.btn-menu-text {
  font-size: 14px;
  font-weight: 700;
}
.cdk-overlay-pane {
  border-radius: 15px !important;
}

.notification-list {
  height: 500px;
  width: 400px;
  overflow: auto;

  .notifications-container {
    display: flex;
    flex-direction: column;
    width: 385px;
    cursor: pointer;

    .notification-container {
      padding: 5px 15px;
      .notification {
        display: flex;
        flex-direction: column;
        gap: 7px;
        padding: 10px 0;

        .notification-title-container {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          .notification-title {
            font-size: 14px;
            font-weight: 700;
          }

          .notification-title-date-container {
            display: flex;
            gap: 5px;
            align-items: center;

            .notification-title-date-description {
              font-size: 13px;
              font-weight: 300;
            }
          }
        }

        .notification-description-description {
          // margin-top: 10px;
          .notification-description {
            font-size: 12px;
          }
          .notification-description-doc-name {
            font-size: 13px;
            font-weight: 700;
          }
        }

        .notification-body {
          font-size: 12px;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }
  .null-notifications {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 400px;
    font-size: 25px;
    color: lightgrey;
  }

  .pending-notifications {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 400px;
    font-size: 25px;
    color: lightgrey;
  }
}

.not-viewed-notif {
  background-color: whitesmoke;
}

.not-viewed-notif-red-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 400px !important;
}

.languages-container-fr {
  margin-left: 20px;
}

.languages-container-dz {
  margin-right: 20px;
}

.selected-lang {
  background-color: lightgray;
}

.mat-badge-content {
  margin-top: 5px;
  background-color: red !important;
  color: white !important;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #219ffb 94%, #0000) top/8px 8px
        no-repeat,
      conic-gradient(#0000 30%, #219ffb);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 8px),
      #000 0
    );
    animation: l13 1s infinite linear;
  }
  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
}

.mat-mdc-menu-content {
  overflow: hidden;
}
