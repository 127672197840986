<div class="minimalWidthDisplay" *ngIf="minimalWidthDisplay">
    <!--folder selector-->
    <div *ngIf="!isAdminContext">
        <button *ngIf="folders && folders.length > 0" mat-button [matMenuTriggerFor]="folderMenu" class="user-button">
            <img *ngIf="selectedFolder && hasImg(selectedFolder) && !emptyFolder" [src]="hasImg(selectedFolder)" alt=""
                class="folder-logo" />
            <div class="folder-acronyms-container" *ngIf="selectedFolder && !hasImg(selectedFolder) && !emptyFolder">
                <div>
                    {{getAccronyms(selectedFolder?.name)}}
                </div>
            </div>
        </button>
        <mat-menu #folderMenu="matMenu" [overlapTrigger]="false">
            <div class="panel-selector" [ngClass]="fuseConfig.layout.toolbar.background">
                <button mat-menu-item *ngFor="let option of options; let i=index;"
                    (click)="goTo('folder', option, 1, i)"
                    [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                    <img ngProjectAs="mat-icon" *ngIf="hasImg(option)" height="30px" width="30px" class="mr-0 mr-sm-8"
                        [src]="hasImg(option)" alt="" />
                    <div *ngIf="!hasImg(option)">
                        <div>
                            {{getAccronyms(option.name)}}
                        </div>
                    </div>
                    {{ option.name }}
                </button>
                <div [matTooltip]="role && role === 'ROLE_USER_CLIENT' ? ('noAccess'|translate):''"
                    style="width: 100%;">
                    <button [disabled]="role && role ==='ROLE_USER_CLIENT'"
                        [ngClass]="{'create-folder-btn accent': role && role !== 'ROLE_USER_CLIENT'}" mat-raised-button
                        style="width: 100%;border-radius: 0 !important;" (click)="goTo('folder')">
                        {{ 'folderSearch.createFolder' | translate }}
                    </button>
                </div>
            </div>
        </mat-menu>
    </div>
    <!-- end folder selector-->

    <!--client account selector-->
    <div *ngIf="isAdminContext">
        <button *ngIf="accounts && accounts.length > 0" mat-button [matMenuTriggerFor]="folderMenu" class="user-button">
            <div class="account-acronyms-container" *ngIf="selectedAccount">
                <div>
                    {{getAccronyms(selectedAccount?.name)}}
                </div>
            </div>
        </button>
        <mat-menu #folderMenu="matMenu" [overlapTrigger]="false">
            <div class="panel-selector" [ngClass]="fuseConfig.layout.toolbar.background">
                <button mat-menu-item class="" *ngFor="let option of adminOptions; let i=index;"
                    (click)="goTo('folder', option, 1, i)"
                    [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                    <div>
                        <div>
                            {{getAccronyms(option.name)}}
                        </div>
                    </div>
                    {{ option.name }}
                </button>
            </div>
        </mat-menu>
    </div>
    <!--client account selector-->
</div>

<div class="notMinimalWidthDisplay" *ngIf="!minimalWidthDisplay">
    <!--folder autocomplete selector-->
    <div *ngIf="!isAdminContext">
        <form *ngIf="folders && folders.length > 0" autocomplete="off" class="folder-form">
            <mat-form-field class="calssInput" appearance="outline">
                <mat-label>{{ 'folderSearch.placeHolder' | translate }}
                </mat-label>
                <input (click)="resetFilterOptions()" #folderInput matInput aria-label="Number"
                    [formControl]="selecorControl" [matAutocomplete]="auto" (input)="selectedFolder = null"
                    style="font-size:16px; line-height: 19px;" />
                <!-- if no img src-->
                <div matSuffix fxLayout="row" *ngIf="selectedFolder && !hasImg(selectedFolder) && !emptyFolder">
                    <div>
                        <mat-icon [matTooltip]="'folderSearch.readMode'|translate" style="margin-top: 7px;"
                            *ngIf="isAccountClientExpired">remove_red_eye
                        </mat-icon>
                    </div>
                    <div (click)="resetFilterOptions()" class="logo-accro"
                        *ngIf="selectedFolder && !hasImg(selectedFolder) && !emptyFolder">
                        <div>
                            {{getAccronyms(selectedFolder?.name)}}
                        </div>
                    </div>
                </div>
                <!--if has img src-->
                <div matSuffix fxLayout="row" *ngIf="selectedFolder && hasImg(selectedFolder) && !emptyFolder">
                    <div>
                        <mat-icon [matTooltip]="'folderSearch.readMode'|translate" style="margin-top: 7px;"
                            *ngIf="isAccountClientExpired">remove_red_eye
                        </mat-icon>
                    </div>
                    <img (click)="folderInput.focus()" *ngIf="selectedFolder && hasImg(selectedFolder) && !emptyFolder"
                        alt="" class="folder-logo folder-logo-mrg" [src]="hasImg(selectedFolder)" />
                </div>
                <mat-autocomplete class="autocomplete-select" #auto="matAutocomplete" [autoActiveFirstOption]=true
                    (closed)="blur()">
                    <div class="panel-selector-autocomplete" [ngClass]="fuseConfig.layout.toolbar.background">
                        <mat-option matSuffix (click)="goTo('folder', option, 1, i)" [disabled]="!option.activated"
                            (onSelectionChange)="onEnter($event,'folder', option, 1, i)"
                            *ngFor="let option of filteredOptions | async; let i=index;" [value]="option.name">
                            <img *ngIf="hasImg(option)" class="create-folder-option-img" [src]="hasImg(option)"
                                alt="" />
                            <div *ngIf="!hasImg(option)" class="create-folder-option-no-image">
                                <div>
                                    {{getAccronyms(option.name)}}
                                </div>
                            </div>
                            {{ option.name }}
                            <!-- <mat-icon *ngIf="accounts && !hasPermission(option)">
                                remove_red_eye</mat-icon> -->
                            <mat-icon *ngIf="!option.activated" style="margin-bottom: 3px;">
                                lock</mat-icon>
                        </mat-option>
                    </div>
                    <div [matTooltip]="role && role === 'ROLE_USER_CLIENT' ? ('noAccess'|translate):''"
                        style="width: 100%;">
                        <button [disabled]="(role && role ==='ROLE_USER_CLIENT')" mat-raised-button
                            [matTooltip]="isAccountClientExpired ? ('expiredAbonnement'|translate):''" [ngClass]="{'create-folder-btn accent': (role && role !== 'ROLE_USER_CLIENT') && isAccountClientExpired === false, 
                        'sirh-disabled-btn':isAccountClientExpired}" style="width: 100%"
                            (click)="isAccountClientExpired ? showMessage() :goTo('folder')">
                            {{ 'folderSearch.createFolder' | translate }}
                        </button>
                    </div>
                </mat-autocomplete>

            </mat-form-field>
        </form>
        <div [matTooltip]="role && role === 'ROLE_USER_CLIENT' ? ('noAccess'|translate):''" style="width: 100%;"
            *ngIf="folders && folders.length === 0">
            <button [disabled]="(role && role ==='ROLE_USER_CLIENT')" mat-raised-button
                [matTooltip]="isAccountClientExpired ? ('expiredAbonnement'|translate):''" class="createFolder"
                (click)="isAccountClientExpired ? showMessage() :goTo('folder')">
                {{ 'folderSearch.createFolder' | translate }}
            </button>
        </div>
    </div>
    <!-- end folder autocomplete selector-->

    <!--client account autocomplete selector-->
    <div *ngIf="isAdminContext">
        <form autocomplete="off" class="folder-form">
            <mat-form-field class="calssInput" appearance="outline" (click)="resetFilterOptions()">
                <mat-label>{{ 'accountSearch.placeHolder' | translate }}
                </mat-label>
                <input matInput aria-label="Number" [formControl]="selecorControl" [matAutocomplete]="autoAdmin"
                    (change)="selectedAccount = null" style="font-size:16px; line-height: 19px;" />
                <div class="logo-accro2" *ngIf="selectedAccount" matSuffix>
                    <div>
                        {{getAccronyms(selectedAccount?.name)}}
                    </div>
                </div>
                <mat-autocomplete #autoAdmin="matAutocomplete" [autoActiveFirstOption]=true (closed)="blur()">
                    <div class="panel-selector-autocomplete" [ngClass]="fuseConfig.layout.toolbar.background">
                        <mat-option (click)="goTo('account', option, 1, i)"
                            (onSelectionChange)="onEnter($event,'account', option, 1, i)"
                            *ngFor="let option of filteredOptions | async; let i=index;" [value]="option.name"
                            [ngStyle]="{'color': fuseConfig.layout.toolbar.customBackgroundColor ? 'white': 'unset'}">
                            <div>
                                <div>
                                    {{getAccronyms(option.name)}}
                                </div>
                            </div>

                            {{ option.name }}
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
    <!--client account autocomplete selector-->
</div>